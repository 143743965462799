:root {
  --radius-zero: 0;
  --radius-extra-small: 0.25rem;
  --radius-small: 0.5rem;
  --radius-medium: 1rem;
  --radius-large: 1.5rem;
  --radius-extra-large: 2rem;
  --radius-extra-extra-large: 3rem;
  --radius-full: 62.4375rem;
}

:root {
  --strokes-1: 0.0625rem;
  --strokes-2: 0.125rem;
}

:root {
  --spacing-component-0: 0rem;
  --spacing-component-1: 0.125rem;
  --spacing-component-2: 0.25rem;
  --spacing-component-3: 0.5rem;
  --spacing-component-4: 0.75rem;
  --spacing-component-5: 1rem;
  --spacing-component-6: 1.25rem;
  --spacing-component-7: 1.5rem;
  --spacing-component-8: 2rem;
  --spacing-component-9: 2.5rem;
  --spacing-component-10: 3rem;
  --spacing-layout-0: 0rem;
  --spacing-layout-1: 1rem;
  --spacing-layout-2: 1.5rem;
  --spacing-layout-3: 2rem;
  --spacing-layout-4: 2.5rem;
  --spacing-layout-5: 3rem;
  --spacing-layout-6: 3.5rem;
  --spacing-layout-7: 4rem;
  --spacing-layout-8: 5rem;
  --spacing-layout-9: 6rem;
  --spacing-layout-10: 8rem;
}

:root {
  --sizing-component-0: 0;
  --sizing-component-1: 0.0625rem;
  --sizing-component-2: 0.125rem;
  --sizing-component-3: 0.25rem;
  --sizing-component-4: 0.5rem;
  --sizing-component-5: 0.625rem;
  --sizing-component-6: 0.75rem;
  --sizing-component-7: 1rem;
  --sizing-component-8: 1.25rem;
  --sizing-component-9: 1.375rem;
  --sizing-component-10: 1.5rem;
  --sizing-component-11: 1.75rem;
  --sizing-component-12: 2rem;
  --sizing-component-13: 2.5rem;
  --sizing-component-14: 3rem;
  --sizing-component-15: 4rem;
  --sizing-component-16: 4.5rem;
  --sizing-component-17: 5rem;
  --sizing-component-18: 5.5rem;
  --sizing-component-19: 9rem;
  --sizing-component-20: 10rem;
  --sizing-input-height: clamp(4.5rem, 2.2222vw + 4rem, 5.5rem);
  --sizing-text-area-height: clamp(9rem, 2.2222vw + 8.5rem, 10rem);
  --sizing-viewport: clamp(22.5rem, 150vw + -11.25rem, 90rem);
  --sizing-minimum-tappable-area: 3rem;
}

:root {
  --mode-basic-background: #ffffff;
  --mode-basic-background-subtle: #fafafa;
  --mode-basic-text: #2a292e;
  --mode-basic-text-dominant: #f87a1d;
  --mode-basic-text-recessive: #807f85;
  --mode-basic-text-link: #f87a1d;
  --mode-basic-accent: #f87a1d;
  --mode-basic-text-on-accent: #ffffff;
  --mode-basic-accent-secondary: #2a292e;
  --mode-basic-text-on-secondary-accent: #ffffff;
  --mode-basic-stroke: #f87a1d;
  --mode-basic-stroke-subtle: #efeef1;
  --mode-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --mode-shades-accent-shades-accent-subtle: #fef2e8;
  --mode-shades-accent-shades-accent-extra-dominant: #fbaf77;
  --mode-shades-accent-shades-accent-dominant: #f9954a;
  --mode-shades-accent-shades-accent-recessive: #954911;
  --mode-shades-accent-shades-accent-extra-recessive: #63310c;
  --mode-shades-secondary-accent-shades-secondary-accent-subtle: #fafafa;
  --mode-shades-secondary-accent-shades-secondary-accent-extra-dominant: #000000;
  --mode-shades-secondary-accent-shades-secondary-accent-dominant: #2a292e;
  --mode-shades-secondary-accent-shades-secondary-accent-recessive: #6c6a70;
  --mode-shades-secondary-accent-shades-secondary-accent-extra-recessive: #807f85;
  --mode-shades-neutral-shades-100: #fafafa;
  --mode-shades-neutral-shades-200: #efeef1;
  --mode-shades-neutral-shades-300: #d9d8dd;
  --mode-shades-neutral-shades-400: #c8c7cd;
  --mode-shades-neutral-shades-500: #aeadb4;
  --mode-shades-neutral-shades-600: #807f85;
  --mode-shades-neutral-shades-700: #6c6a70;
  --mode-shades-neutral-shades-800: #2a292e;
  --mode-shades-neutral-shades-900: #000000;
  --mode-interaction-states-hover-background-hover: #fafafa;
  --mode-interaction-states-hover-background-subtle-hover: #efeef1;
  --mode-interaction-states-hover-text-hover: #151a20;
  --mode-interaction-states-hover-text-dominant-hover: #f9954a;
  --mode-interaction-states-hover-text-recessive-hover: #f9954a;
  --mode-interaction-states-hover-text-link-hover: #f9954a;
  --mode-interaction-states-hover-accent-hover: #f9954a;
  --mode-interaction-states-hover-text-on-accent-hover: #ffffff;
  --mode-interaction-states-hover-accent-secondary-hover: #000000;
  --mode-interaction-states-hover-text-on-accent-secondary-hover: #ffffff;
  --mode-interaction-states-hover-stroke-hover: #f9954a;
  --mode-interaction-states-hover-stroke-subtle-hover: #d8dde9;
  --mode-interaction-states-pressed-background-pressed: #fef2e8;
  --mode-interaction-states-pressed-background-subtle-pressed: #d9d8dd;
  --mode-interaction-states-pressed-text-pressed: #151a20;
  --mode-interaction-states-pressed-text-dominant-pressed: #fbaf77;
  --mode-interaction-states-pressed-text-recessive-pressed: #fbaf77;
  --mode-interaction-states-pressed-text-link-pressed: #fbaf77;
  --mode-interaction-states-pressed-accent-pressed: #fbaf77;
  --mode-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --mode-interaction-states-pressed-accent-secondary-pressed: #2a292e;
  --mode-interaction-states-pressed-text-on-accent-secondary-pressed: #ffffff;
  --mode-interaction-states-pressed-stroke-pressed: #fbaf77;
  --mode-interaction-states-pressed-stroke-subtle-pressed: #efeef1;
  --mode-interaction-states-focus-focus: #fafafa;
  --mode-interaction-states-focus-stroke-active: #f87a1d;
  --mode-interaction-states-disabled-background-disabled: #ffffff;
  --mode-interaction-states-disabled-background-subtle-disabled: #fafafa;
  --mode-interaction-states-disabled-text-disabled: #807f85;
  --mode-interaction-states-disabled-text-dominant-disabled: #fbaf77;
  --mode-interaction-states-disabled-text-recessive-disabled: #aeadb4;
  --mode-interaction-states-disabled-text-link-disabled: #fbaf77;
  --mode-interaction-states-disabled-accent-disabled: #fbaf77;
  --mode-interaction-states-disabled-text-on-accent-disabled: #ffffff;
  --mode-interaction-states-disabled-accent-secondary-disabled: #c8c7cd;
  --mode-interaction-states-disabled-text-on-accent-secondary-disabled: #ffffff;
  --mode-interaction-states-disabled-stroke-disabled: #fbaf77;
  --mode-interaction-states-disabled-stroke-subtle-disabled: #efeef1;
  --mode-interaction-states-visited-text-visited: #fafafa;
  --mode-interaction-states-visited-text-link-visited: #f87a1d;
  --mode-functional-notification-notification: #0c9ce2;
  --mode-functional-notification-text-on-notification: #e7f5fc;
  --mode-functional-informational-standard: #8f83ff;
  --mode-functional-informational-subtle: #d2cdff;
  --mode-functional-informational-hover: #a59cff;
  --mode-functional-informational-pressed: #3f349c;
  --mode-functional-success-standard: #00b381;
  --mode-functional-success-subtle: #c6f7e9;
  --mode-functional-success-hover: #2cc298;
  --mode-functional-success-pressed: #004834;
  --mode-functional-warning-standard: #f3c40a;
  --mode-functional-warning-subtle: #fef9e7;
  --mode-functional-warning-hover: #f8dc6c;
  --mode-functional-warning-pressed: #614e04;
  --mode-functional-destructive-standard: #cc0940;
  --mode-functional-destructive-subtle: #f5cdd8;
  --mode-functional-destructive-hover: #e06b8c;
  --mode-functional-destructive-pressed: #52041a;
  --mode-elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --mode-elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --mode-elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --mode-elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --mode-elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --mode-elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
  --mode-inverted-basic-background: #151a20;
  --mode-inverted-basic-background-subtle: #303540;
  --mode-inverted-basic-text: #efeef1;
  --mode-inverted-basic-text-dominant: #f87a1d;
  --mode-inverted-basic-text-recessive: #c8c7cd;
  --mode-inverted-basic-text-link: #f87a1d;
  --mode-inverted-basic-accent: #f87a1d;
  --mode-inverted-basic-text-on-accent: #151a20;
  --mode-inverted-basic-accent-secondary: #ffffff;
  --mode-inverted-basic-text-on-secondary-accent: #151a20;
  --mode-inverted-basic-stroke: #f87a1d;
  --mode-inverted-basic-stroke-subtle: #4c5160;
  --mode-inverted-basic-modal-overlay: rgba(0, 0, 0, 0.8);
  --mode-inverted-shades-accent-shades-accent-subtle: #fef2e8;
  --mode-inverted-shades-accent-shades-accent-extra-dominant: #fbaf77;
  --mode-inverted-shades-accent-shades-accent-dominant: #f9954a;
  --mode-inverted-shades-accent-shades-accent-recessive: #954911;
  --mode-inverted-shades-accent-shades-accent-extra-recessive: #63310c;
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-subtle: #2a292e;
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-extra-dominant: #fafafa;
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-dominant: #efeef1;
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-recessive: #d9d8dd;
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-extra-recessive: #c8c7cd;
  --mode-inverted-shades-neutral-shades-100: #151a20;
  --mode-inverted-shades-neutral-shades-200: #1f2229;
  --mode-inverted-shades-neutral-shades-300: #303540;
  --mode-inverted-shades-neutral-shades-400: #4c5160;
  --mode-inverted-shades-neutral-shades-500: #757c8f;
  --mode-inverted-shades-neutral-shades-600: #838a9f;
  --mode-inverted-shades-neutral-shades-700: #9099af;
  --mode-inverted-shades-neutral-shades-800: #d8dde9;
  --mode-inverted-shades-neutral-shades-900: #f2f1f8;
  --mode-inverted-interaction-states-hover-background-hover: #190c03;
  --mode-inverted-interaction-states-hover-background-subtle-hover: #6c6a70;
  --mode-inverted-interaction-states-hover-text-hover: #ffffff;
  --mode-inverted-interaction-states-hover-text-dominant-hover: #954911;
  --mode-inverted-interaction-states-hover-text-recessive-hover: #954911;
  --mode-inverted-interaction-states-hover-text-link-hover: #954911;
  --mode-inverted-interaction-states-hover-accent-hover: #954911;
  --mode-inverted-interaction-states-hover-text-on-accent-hover: #151a20;
  --mode-inverted-interaction-states-hover-accent-secondary-hover: #fafafa;
  --mode-inverted-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --mode-inverted-interaction-states-hover-stroke-hover: #954911;
  --mode-inverted-interaction-states-hover-stroke-subtle-hover: #303540;
  --mode-inverted-interaction-states-pressed-background-pressed: #6c6a70;
  --mode-inverted-interaction-states-pressed-background-subtle-pressed: #807f85;
  --mode-inverted-interaction-states-pressed-text-pressed: #ffffff;
  --mode-inverted-interaction-states-pressed-text-dominant-pressed: #f9954a;
  --mode-inverted-interaction-states-pressed-text-recessive-pressed: #f9954a;
  --mode-inverted-interaction-states-pressed-text-link-pressed: #f9954a;
  --mode-inverted-interaction-states-pressed-accent-pressed: #f9954a;
  --mode-inverted-interaction-states-pressed-text-on-accent-pressed: #151a20;
  --mode-inverted-interaction-states-pressed-accent-secondary-pressed: #efeef1;
  --mode-inverted-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --mode-inverted-interaction-states-pressed-stroke-pressed: #f9954a;
  --mode-inverted-interaction-states-pressed-stroke-subtle-pressed: #303540;
  --mode-inverted-interaction-states-focus-focus: #1f2229;
  --mode-inverted-interaction-states-focus-stroke-active: #f87a1d;
  --mode-inverted-interaction-states-disabled-background-disabled: #1f2229;
  --mode-inverted-interaction-states-disabled-background-subtle-disabled: #4c5160;
  --mode-inverted-interaction-states-disabled-text-disabled: #aeadb4;
  --mode-inverted-interaction-states-disabled-text-dominant-disabled: #954911;
  --mode-inverted-interaction-states-disabled-text-recessive-disabled: #807f85;
  --mode-inverted-interaction-states-disabled-text-link-disabled: #954911;
  --mode-inverted-interaction-states-disabled-accent-disabled: #954911;
  --mode-inverted-interaction-states-disabled-text-on-accent-disabled: #151a20;
  --mode-inverted-interaction-states-disabled-accent-secondary-disabled: #2a292e;
  --mode-inverted-interaction-states-disabled-text-on-accent-secondary-disabled: #151a20;
  --mode-inverted-interaction-states-disabled-stroke-disabled: #63310c;
  --mode-inverted-interaction-states-disabled-stroke-subtle-disabled: #6c6a70;
  --mode-inverted-interaction-states-visited-text-visited: #1f2229;
  --mode-inverted-interaction-states-visited-text-link-visited: #f87a1d;
  --mode-inverted-functional-notification-notification: #3db0e8;
  --mode-inverted-functional-notification-text-on-notification: #151a20;
  --mode-inverted-functional-informational-standard: #a59cff;
  --mode-inverted-functional-informational-subtle: #d2cdff;
  --mode-inverted-functional-informational-hover: #a59cff;
  --mode-inverted-functional-informational-pressed: #3f349c;
  --mode-inverted-functional-success-standard: #2cc298;
  --mode-inverted-functional-success-subtle: #c6f7e9;
  --mode-inverted-functional-success-hover: #2cc298;
  --mode-inverted-functional-success-pressed: #004834;
  --mode-inverted-functional-warning-standard: #f5d03b;
  --mode-inverted-functional-warning-subtle: #fef9e7;
  --mode-inverted-functional-warning-hover: #f8dc6c;
  --mode-inverted-functional-warning-pressed: #614e04;
  --mode-inverted-functional-destructive-standard: #d63a66;
  --mode-inverted-functional-destructive-subtle: #f5cdd8;
  --mode-inverted-functional-destructive-hover: #e06b8c;
  --mode-inverted-functional-destructive-pressed: #52041a;
  --mode-inverted-elevation-level-1: 0 1px 0 0 rgba(0, 0, 0, 0.5);
  --mode-inverted-elevation-level-2: 0 1px 4px 0 rgba(0, 0, 0, 0.5), 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
  --mode-inverted-elevation-level-3: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
  --mode-inverted-elevation-level-4: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
  --mode-inverted-elevation-level-5: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  --mode-inverted-elevation-level-6: 0 12px 40px 0 #000000a6;
}

[data-mode='dark'] {
  --mode-basic-background: #151a20;
  --mode-basic-background-subtle: #303540;
  --mode-basic-text: #efeef1;
  --mode-basic-text-dominant: #f87a1d;
  --mode-basic-text-recessive: #c8c7cd;
  --mode-basic-text-link: #f87a1d;
  --mode-basic-accent: #f87a1d;
  --mode-basic-text-on-accent: #151a20;
  --mode-basic-accent-secondary: #ffffff;
  --mode-basic-text-on-secondary-accent: #151a20;
  --mode-basic-stroke: #f87a1d;
  --mode-basic-stroke-subtle: #4c5160;
  --mode-basic-modal-overlay: rgba(0, 0, 0, 0.8);
  --mode-shades-accent-shades-accent-subtle: #fef2e8;
  --mode-shades-accent-shades-accent-extra-dominant: #fbaf77;
  --mode-shades-accent-shades-accent-dominant: #f9954a;
  --mode-shades-accent-shades-accent-recessive: #954911;
  --mode-shades-accent-shades-accent-extra-recessive: #63310c;
  --mode-shades-secondary-accent-shades-secondary-accent-subtle: #2a292e;
  --mode-shades-secondary-accent-shades-secondary-accent-extra-dominant: #fafafa;
  --mode-shades-secondary-accent-shades-secondary-accent-dominant: #efeef1;
  --mode-shades-secondary-accent-shades-secondary-accent-recessive: #d9d8dd;
  --mode-shades-secondary-accent-shades-secondary-accent-extra-recessive: #c8c7cd;
  --mode-shades-neutral-shades-100: #151a20;
  --mode-shades-neutral-shades-200: #1f2229;
  --mode-shades-neutral-shades-300: #303540;
  --mode-shades-neutral-shades-400: #4c5160;
  --mode-shades-neutral-shades-500: #757c8f;
  --mode-shades-neutral-shades-600: #838a9f;
  --mode-shades-neutral-shades-700: #9099af;
  --mode-shades-neutral-shades-800: #d8dde9;
  --mode-shades-neutral-shades-900: #f2f1f8;
  --mode-interaction-states-hover-background-hover: #190c03;
  --mode-interaction-states-hover-background-subtle-hover: #6c6a70;
  --mode-interaction-states-hover-text-hover: #ffffff;
  --mode-interaction-states-hover-text-dominant-hover: #954911;
  --mode-interaction-states-hover-text-recessive-hover: #954911;
  --mode-interaction-states-hover-text-link-hover: #954911;
  --mode-interaction-states-hover-accent-hover: #954911;
  --mode-interaction-states-hover-text-on-accent-hover: #151a20;
  --mode-interaction-states-hover-accent-secondary-hover: #fafafa;
  --mode-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --mode-interaction-states-hover-stroke-hover: #954911;
  --mode-interaction-states-hover-stroke-subtle-hover: #303540;
  --mode-interaction-states-pressed-background-pressed: #6c6a70;
  --mode-interaction-states-pressed-background-subtle-pressed: #807f85;
  --mode-interaction-states-pressed-text-pressed: #ffffff;
  --mode-interaction-states-pressed-text-dominant-pressed: #f9954a;
  --mode-interaction-states-pressed-text-recessive-pressed: #f9954a;
  --mode-interaction-states-pressed-text-link-pressed: #f9954a;
  --mode-interaction-states-pressed-accent-pressed: #f9954a;
  --mode-interaction-states-pressed-text-on-accent-pressed: #151a20;
  --mode-interaction-states-pressed-accent-secondary-pressed: #efeef1;
  --mode-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --mode-interaction-states-pressed-stroke-pressed: #f9954a;
  --mode-interaction-states-pressed-stroke-subtle-pressed: #303540;
  --mode-interaction-states-focus-focus: #1f2229;
  --mode-interaction-states-focus-stroke-active: #f87a1d;
  --mode-interaction-states-disabled-background-disabled: #1f2229;
  --mode-interaction-states-disabled-background-subtle-disabled: #4c5160;
  --mode-interaction-states-disabled-text-disabled: #6c6a70;
  --mode-interaction-states-disabled-text-dominant-disabled: #954911;
  --mode-interaction-states-disabled-text-recessive-disabled: #807f85;
  --mode-interaction-states-disabled-text-link-disabled: #954911;
  --mode-interaction-states-disabled-accent-disabled: #954911;
  --mode-interaction-states-disabled-text-on-accent-disabled: #151a20;
  --mode-interaction-states-disabled-accent-secondary-disabled: #2a292e;
  --mode-interaction-states-disabled-text-on-accent-secondary-disabled: #151a20;
  --mode-interaction-states-disabled-stroke-disabled: #63310c;
  --mode-interaction-states-disabled-stroke-subtle-disabled: #6c6a70;
  --mode-interaction-states-visited-text-visited: #1f2229;
  --mode-interaction-states-visited-text-link-visited: #f87a1d;
  --mode-functional-notification-notification: #3db0e8;
  --mode-functional-notification-text-on-notification: #151a20;
  --mode-functional-informational-standard: #a59cff;
  --mode-functional-informational-subtle: #d2cdff;
  --mode-functional-informational-hover: #a59cff;
  --mode-functional-informational-pressed: #3f349c;
  --mode-functional-success-standard: #2cc298;
  --mode-functional-success-subtle: #c6f7e9;
  --mode-functional-success-hover: #2cc298;
  --mode-functional-success-pressed: #004834;
  --mode-functional-warning-standard: #f5d03b;
  --mode-functional-warning-subtle: #fef9e7;
  --mode-functional-warning-hover: #f8dc6c;
  --mode-functional-warning-pressed: #614e04;
  --mode-functional-destructive-standard: #d63a66;
  --mode-functional-destructive-subtle: #f5cdd8;
  --mode-functional-destructive-hover: #e06b8c;
  --mode-functional-destructive-pressed: #52041a;
  --mode-elevation-level-1: 0 1px 0 0 rgba(0, 0, 0, 0.5);
  --mode-elevation-level-2: 0 1px 4px 0 rgba(0, 0, 0, 0.5), 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
  --mode-elevation-level-3: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
  --mode-elevation-level-4: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
  --mode-elevation-level-5: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  --mode-elevation-level-6: 0 12px 40px 0 #000000a6;
  --mode-inverted-basic-background: #ffffff;
  --mode-inverted-basic-background-subtle: #fafafa;
  --mode-inverted-basic-text: #2a292e;
  --mode-inverted-basic-text-dominant: #f87a1d;
  --mode-inverted-basic-text-recessive: #807f85;
  --mode-inverted-basic-text-link: #f87a1d;
  --mode-inverted-basic-accent: #f87a1d;
  --mode-inverted-basic-text-on-accent: #ffffff;
  --mode-inverted-basic-accent-secondary: #2a292e;
  --mode-inverted-basic-text-on-secondary-accent: #ffffff;
  --mode-inverted-basic-stroke: #f87a1d;
  --mode-inverted-basic-stroke-subtle: #efeef1;
  --mode-inverted-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --mode-inverted-shades-accent-shades-accent-subtle: #fef2e8;
  --mode-inverted-shades-accent-shades-accent-extra-dominant: #fbaf77;
  --mode-inverted-shades-accent-shades-accent-dominant: #f9954a;
  --mode-inverted-shades-accent-shades-accent-recessive: #954911;
  --mode-inverted-shades-accent-shades-accent-extra-recessive: #63310c;
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-subtle: #fafafa;
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-extra-dominant: #000000;
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-dominant: #2a292e;
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-recessive: #6c6a70;
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-extra-recessive: #807f85;
  --mode-inverted-shades-neutral-shades-100: #fafafa;
  --mode-inverted-shades-neutral-shades-200: #efeef1;
  --mode-inverted-shades-neutral-shades-300: #d9d8dd;
  --mode-inverted-shades-neutral-shades-400: #c8c7cd;
  --mode-inverted-shades-neutral-shades-500: #aeadb4;
  --mode-inverted-shades-neutral-shades-600: #807f85;
  --mode-inverted-shades-neutral-shades-700: #6c6a70;
  --mode-inverted-shades-neutral-shades-800: #2a292e;
  --mode-inverted-shades-neutral-shades-900: #000000;
  --mode-inverted-interaction-states-hover-background-hover: #fafafa;
  --mode-inverted-interaction-states-hover-background-subtle-hover: #efeef1;
  --mode-inverted-interaction-states-hover-text-hover: #151a20;
  --mode-inverted-interaction-states-hover-text-dominant-hover: #f9954a;
  --mode-inverted-interaction-states-hover-text-recessive-hover: #f9954a;
  --mode-inverted-interaction-states-hover-text-link-hover: #f9954a;
  --mode-inverted-interaction-states-hover-accent-hover: #f9954a;
  --mode-inverted-interaction-states-hover-text-on-accent-hover: #ffffff;
  --mode-inverted-interaction-states-hover-accent-secondary-hover: #000000;
  --mode-inverted-interaction-states-hover-text-on-accent-secondary-hover: #ffffff;
  --mode-inverted-interaction-states-hover-stroke-hover: #f9954a;
  --mode-inverted-interaction-states-hover-stroke-subtle-hover: #d8dde9;
  --mode-inverted-interaction-states-pressed-background-pressed: #fef2e8;
  --mode-inverted-interaction-states-pressed-background-subtle-pressed: #d9d8dd;
  --mode-inverted-interaction-states-pressed-text-pressed: #151a20;
  --mode-inverted-interaction-states-pressed-text-dominant-pressed: #fbaf77;
  --mode-inverted-interaction-states-pressed-text-recessive-pressed: #fbaf77;
  --mode-inverted-interaction-states-pressed-text-link-pressed: #fbaf77;
  --mode-inverted-interaction-states-pressed-accent-pressed: #fbaf77;
  --mode-inverted-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --mode-inverted-interaction-states-pressed-accent-secondary-pressed: #2a292e;
  --mode-inverted-interaction-states-pressed-text-on-accent-secondary-pressed: #ffffff;
  --mode-inverted-interaction-states-pressed-stroke-pressed: #fbaf77;
  --mode-inverted-interaction-states-pressed-stroke-subtle-pressed: #efeef1;
  --mode-inverted-interaction-states-focus-focus: #fafafa;
  --mode-inverted-interaction-states-focus-stroke-active: #f87a1d;
  --mode-inverted-interaction-states-disabled-background-disabled: #ffffff;
  --mode-inverted-interaction-states-disabled-background-subtle-disabled: #fafafa;
  --mode-inverted-interaction-states-disabled-text-disabled: #efeef1;
  --mode-inverted-interaction-states-disabled-text-dominant-disabled: #fbaf77;
  --mode-inverted-interaction-states-disabled-text-recessive-disabled: #d9d8dd;
  --mode-inverted-interaction-states-disabled-text-link-disabled: #fbaf77;
  --mode-inverted-interaction-states-disabled-accent-disabled: #fbaf77;
  --mode-inverted-interaction-states-disabled-text-on-accent-disabled: #ffffff;
  --mode-inverted-interaction-states-disabled-accent-secondary-disabled: #c8c7cd;
  --mode-inverted-interaction-states-disabled-text-on-accent-secondary-disabled: #ffffff;
  --mode-inverted-interaction-states-disabled-stroke-disabled: #fbaf77;
  --mode-inverted-interaction-states-disabled-stroke-subtle-disabled: #efeef1;
  --mode-inverted-interaction-states-visited-text-visited: #fafafa;
  --mode-inverted-interaction-states-visited-text-link-visited: #f87a1d;
  --mode-inverted-functional-notification-notification: #0c9ce2;
  --mode-inverted-functional-notification-text-on-notification: #e7f5fc;
  --mode-inverted-functional-informational-standard: #8f83ff;
  --mode-inverted-functional-informational-subtle: #d2cdff;
  --mode-inverted-functional-informational-hover: #a59cff;
  --mode-inverted-functional-informational-pressed: #3f349c;
  --mode-inverted-functional-success-standard: #00b381;
  --mode-inverted-functional-success-subtle: #c6f7e9;
  --mode-inverted-functional-success-hover: #2cc298;
  --mode-inverted-functional-success-pressed: #004834;
  --mode-inverted-functional-warning-standard: #f3c40a;
  --mode-inverted-functional-warning-subtle: #fef9e7;
  --mode-inverted-functional-warning-hover: #f8dc6c;
  --mode-inverted-functional-warning-pressed: #614e04;
  --mode-inverted-functional-destructive-standard: #cc0940;
  --mode-inverted-functional-destructive-subtle: #f5cdd8;
  --mode-inverted-functional-destructive-hover: #e06b8c;
  --mode-inverted-functional-destructive-pressed: #52041a;
  --mode-inverted-elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --mode-inverted-elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --mode-inverted-elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --mode-inverted-elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --mode-inverted-elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --mode-inverted-elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}

@media (prefers-color-scheme: dark) {
  :root {
    --mode-basic-background: #151a20;
    --mode-basic-background-subtle: #303540;
    --mode-basic-text: #efeef1;
    --mode-basic-text-dominant: #f87a1d;
    --mode-basic-text-recessive: #c8c7cd;
    --mode-basic-text-link: #f87a1d;
    --mode-basic-accent: #f87a1d;
    --mode-basic-text-on-accent: #151a20;
    --mode-basic-accent-secondary: #ffffff;
    --mode-basic-text-on-secondary-accent: #151a20;
    --mode-basic-stroke: #f87a1d;
    --mode-basic-stroke-subtle: #4c5160;
    --mode-basic-modal-overlay: rgba(0, 0, 0, 0.8);
    --mode-shades-accent-shades-accent-subtle: #fef2e8;
    --mode-shades-accent-shades-accent-extra-dominant: #fbaf77;
    --mode-shades-accent-shades-accent-dominant: #f9954a;
    --mode-shades-accent-shades-accent-recessive: #954911;
    --mode-shades-accent-shades-accent-extra-recessive: #63310c;
    --mode-shades-secondary-accent-shades-secondary-accent-subtle: #2a292e;
    --mode-shades-secondary-accent-shades-secondary-accent-extra-dominant: #fafafa;
    --mode-shades-secondary-accent-shades-secondary-accent-dominant: #efeef1;
    --mode-shades-secondary-accent-shades-secondary-accent-recessive: #d9d8dd;
    --mode-shades-secondary-accent-shades-secondary-accent-extra-recessive: #c8c7cd;
    --mode-shades-neutral-shades-100: #151a20;
    --mode-shades-neutral-shades-200: #1f2229;
    --mode-shades-neutral-shades-300: #303540;
    --mode-shades-neutral-shades-400: #4c5160;
    --mode-shades-neutral-shades-500: #757c8f;
    --mode-shades-neutral-shades-600: #838a9f;
    --mode-shades-neutral-shades-700: #9099af;
    --mode-shades-neutral-shades-800: #d8dde9;
    --mode-shades-neutral-shades-900: #f2f1f8;
    --mode-interaction-states-hover-background-hover: #190c03;
    --mode-interaction-states-hover-background-subtle-hover: #6c6a70;
    --mode-interaction-states-hover-text-hover: #ffffff;
    --mode-interaction-states-hover-text-dominant-hover: #954911;
    --mode-interaction-states-hover-text-recessive-hover: #954911;
    --mode-interaction-states-hover-text-link-hover: #954911;
    --mode-interaction-states-hover-accent-hover: #954911;
    --mode-interaction-states-hover-text-on-accent-hover: #151a20;
    --mode-interaction-states-hover-accent-secondary-hover: #fafafa;
    --mode-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
    --mode-interaction-states-hover-stroke-hover: #954911;
    --mode-interaction-states-hover-stroke-subtle-hover: #303540;
    --mode-interaction-states-pressed-background-pressed: #6c6a70;
    --mode-interaction-states-pressed-background-subtle-pressed: #807f85;
    --mode-interaction-states-pressed-text-pressed: #ffffff;
    --mode-interaction-states-pressed-text-dominant-pressed: #f9954a;
    --mode-interaction-states-pressed-text-recessive-pressed: #f9954a;
    --mode-interaction-states-pressed-text-link-pressed: #f9954a;
    --mode-interaction-states-pressed-accent-pressed: #f9954a;
    --mode-interaction-states-pressed-text-on-accent-pressed: #151a20;
    --mode-interaction-states-pressed-accent-secondary-pressed: #efeef1;
    --mode-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
    --mode-interaction-states-pressed-stroke-pressed: #f9954a;
    --mode-interaction-states-pressed-stroke-subtle-pressed: #303540;
    --mode-interaction-states-focus-focus: #1f2229;
    --mode-interaction-states-focus-stroke-active: #f87a1d;
    --mode-interaction-states-disabled-background-disabled: #1f2229;
    --mode-interaction-states-disabled-background-subtle-disabled: #4c5160;
    --mode-interaction-states-disabled-text-disabled: #6c6a70;
    --mode-interaction-states-disabled-text-dominant-disabled: #954911;
    --mode-interaction-states-disabled-text-recessive-disabled: #807f85;
    --mode-interaction-states-disabled-text-link-disabled: #954911;
    --mode-interaction-states-disabled-accent-disabled: #954911;
    --mode-interaction-states-disabled-text-on-accent-disabled: #151a20;
    --mode-interaction-states-disabled-accent-secondary-disabled: #2a292e;
    --mode-interaction-states-disabled-text-on-accent-secondary-disabled: #151a20;
    --mode-interaction-states-disabled-stroke-disabled: #63310c;
    --mode-interaction-states-disabled-stroke-subtle-disabled: #6c6a70;
    --mode-interaction-states-visited-text-visited: #1f2229;
    --mode-interaction-states-visited-text-link-visited: #f87a1d;
    --mode-functional-notification-notification: #3db0e8;
    --mode-functional-notification-text-on-notification: #151a20;
    --mode-functional-informational-standard: #a59cff;
    --mode-functional-informational-subtle: #d2cdff;
    --mode-functional-informational-hover: #a59cff;
    --mode-functional-informational-pressed: #3f349c;
    --mode-functional-success-standard: #2cc298;
    --mode-functional-success-subtle: #c6f7e9;
    --mode-functional-success-hover: #2cc298;
    --mode-functional-success-pressed: #004834;
    --mode-functional-warning-standard: #f5d03b;
    --mode-functional-warning-subtle: #fef9e7;
    --mode-functional-warning-hover: #f8dc6c;
    --mode-functional-warning-pressed: #614e04;
    --mode-functional-destructive-standard: #d63a66;
    --mode-functional-destructive-subtle: #f5cdd8;
    --mode-functional-destructive-hover: #e06b8c;
    --mode-functional-destructive-pressed: #52041a;
    --mode-elevation-level-1: 0 1px 0 0 rgba(0, 0, 0, 0.5);
    --mode-elevation-level-2: 0 1px 4px 0 rgba(0, 0, 0, 0.5), 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    --mode-elevation-level-3: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    --mode-elevation-level-4: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    --mode-elevation-level-5: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    --mode-elevation-level-6: 0 12px 40px 0 #000000a6;
    --mode-inverted-basic-background: #ffffff;
    --mode-inverted-basic-background-subtle: #fafafa;
    --mode-inverted-basic-text: #2a292e;
    --mode-inverted-basic-text-dominant: #f87a1d;
    --mode-inverted-basic-text-recessive: #807f85;
    --mode-inverted-basic-text-link: #f87a1d;
    --mode-inverted-basic-accent: #f87a1d;
    --mode-inverted-basic-text-on-accent: #ffffff;
    --mode-inverted-basic-accent-secondary: #2a292e;
    --mode-inverted-basic-text-on-secondary-accent: #ffffff;
    --mode-inverted-basic-stroke: #f87a1d;
    --mode-inverted-basic-stroke-subtle: #efeef1;
    --mode-inverted-basic-modal-overlay: rgba(21, 26, 32, 0.5);
    --mode-inverted-shades-accent-shades-accent-subtle: #fef2e8;
    --mode-inverted-shades-accent-shades-accent-extra-dominant: #fbaf77;
    --mode-inverted-shades-accent-shades-accent-dominant: #f9954a;
    --mode-inverted-shades-accent-shades-accent-recessive: #954911;
    --mode-inverted-shades-accent-shades-accent-extra-recessive: #63310c;
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-subtle: #fafafa;
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-extra-dominant: #000000;
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-dominant: #2a292e;
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-recessive: #6c6a70;
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-extra-recessive: #807f85;
    --mode-inverted-shades-neutral-shades-100: #fafafa;
    --mode-inverted-shades-neutral-shades-200: #efeef1;
    --mode-inverted-shades-neutral-shades-300: #d9d8dd;
    --mode-inverted-shades-neutral-shades-400: #c8c7cd;
    --mode-inverted-shades-neutral-shades-500: #aeadb4;
    --mode-inverted-shades-neutral-shades-600: #807f85;
    --mode-inverted-shades-neutral-shades-700: #6c6a70;
    --mode-inverted-shades-neutral-shades-800: #2a292e;
    --mode-inverted-shades-neutral-shades-900: #000000;
    --mode-inverted-interaction-states-hover-background-hover: #fafafa;
    --mode-inverted-interaction-states-hover-background-subtle-hover: #efeef1;
    --mode-inverted-interaction-states-hover-text-hover: #151a20;
    --mode-inverted-interaction-states-hover-text-dominant-hover: #f9954a;
    --mode-inverted-interaction-states-hover-text-recessive-hover: #f9954a;
    --mode-inverted-interaction-states-hover-text-link-hover: #f9954a;
    --mode-inverted-interaction-states-hover-accent-hover: #f9954a;
    --mode-inverted-interaction-states-hover-text-on-accent-hover: #ffffff;
    --mode-inverted-interaction-states-hover-accent-secondary-hover: #000000;
    --mode-inverted-interaction-states-hover-text-on-accent-secondary-hover: #ffffff;
    --mode-inverted-interaction-states-hover-stroke-hover: #f9954a;
    --mode-inverted-interaction-states-hover-stroke-subtle-hover: #d8dde9;
    --mode-inverted-interaction-states-pressed-background-pressed: #fef2e8;
    --mode-inverted-interaction-states-pressed-background-subtle-pressed: #d9d8dd;
    --mode-inverted-interaction-states-pressed-text-pressed: #151a20;
    --mode-inverted-interaction-states-pressed-text-dominant-pressed: #fbaf77;
    --mode-inverted-interaction-states-pressed-text-recessive-pressed: #fbaf77;
    --mode-inverted-interaction-states-pressed-text-link-pressed: #fbaf77;
    --mode-inverted-interaction-states-pressed-accent-pressed: #fbaf77;
    --mode-inverted-interaction-states-pressed-text-on-accent-pressed: #ffffff;
    --mode-inverted-interaction-states-pressed-accent-secondary-pressed: #2a292e;
    --mode-inverted-interaction-states-pressed-text-on-accent-secondary-pressed: #ffffff;
    --mode-inverted-interaction-states-pressed-stroke-pressed: #fbaf77;
    --mode-inverted-interaction-states-pressed-stroke-subtle-pressed: #efeef1;
    --mode-inverted-interaction-states-focus-focus: #fafafa;
    --mode-inverted-interaction-states-focus-stroke-active: #f87a1d;
    --mode-inverted-interaction-states-disabled-background-disabled: #ffffff;
    --mode-inverted-interaction-states-disabled-background-subtle-disabled: #fafafa;
    --mode-inverted-interaction-states-disabled-text-disabled: #efeef1;
    --mode-inverted-interaction-states-disabled-text-dominant-disabled: #fbaf77;
    --mode-inverted-interaction-states-disabled-text-recessive-disabled: #d9d8dd;
    --mode-inverted-interaction-states-disabled-text-link-disabled: #fbaf77;
    --mode-inverted-interaction-states-disabled-accent-disabled: #fbaf77;
    --mode-inverted-interaction-states-disabled-text-on-accent-disabled: #ffffff;
    --mode-inverted-interaction-states-disabled-accent-secondary-disabled: #c8c7cd;
    --mode-inverted-interaction-states-disabled-text-on-accent-secondary-disabled: #ffffff;
    --mode-inverted-interaction-states-disabled-stroke-disabled: #fbaf77;
    --mode-inverted-interaction-states-disabled-stroke-subtle-disabled: #efeef1;
    --mode-inverted-interaction-states-visited-text-visited: #fafafa;
    --mode-inverted-interaction-states-visited-text-link-visited: #f87a1d;
    --mode-inverted-functional-notification-notification: #0c9ce2;
    --mode-inverted-functional-notification-text-on-notification: #e7f5fc;
    --mode-inverted-functional-informational-standard: #8f83ff;
    --mode-inverted-functional-informational-subtle: #d2cdff;
    --mode-inverted-functional-informational-hover: #a59cff;
    --mode-inverted-functional-informational-pressed: #3f349c;
    --mode-inverted-functional-success-standard: #00b381;
    --mode-inverted-functional-success-subtle: #c6f7e9;
    --mode-inverted-functional-success-hover: #2cc298;
    --mode-inverted-functional-success-pressed: #004834;
    --mode-inverted-functional-warning-standard: #f3c40a;
    --mode-inverted-functional-warning-subtle: #fef9e7;
    --mode-inverted-functional-warning-hover: #f8dc6c;
    --mode-inverted-functional-warning-pressed: #614e04;
    --mode-inverted-functional-destructive-standard: #cc0940;
    --mode-inverted-functional-destructive-subtle: #f5cdd8;
    --mode-inverted-functional-destructive-hover: #e06b8c;
    --mode-inverted-functional-destructive-pressed: #52041a;
    --mode-inverted-elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
    --mode-inverted-elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
    --mode-inverted-elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
    --mode-inverted-elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
    --mode-inverted-elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
    --mode-inverted-elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
  }
  [data-mode='light'] {
    --mode-basic-background: #ffffff;
    --mode-basic-background-subtle: #fafafa;
    --mode-basic-text: #2a292e;
    --mode-basic-text-dominant: #f87a1d;
    --mode-basic-text-recessive: #807f85;
    --mode-basic-text-link: #f87a1d;
    --mode-basic-accent: #f87a1d;
    --mode-basic-text-on-accent: #ffffff;
    --mode-basic-accent-secondary: #2a292e;
    --mode-basic-text-on-secondary-accent: #ffffff;
    --mode-basic-stroke: #f87a1d;
    --mode-basic-stroke-subtle: #efeef1;
    --mode-basic-modal-overlay: rgba(21, 26, 32, 0.5);
    --mode-shades-accent-shades-accent-subtle: #fef2e8;
    --mode-shades-accent-shades-accent-extra-dominant: #fbaf77;
    --mode-shades-accent-shades-accent-dominant: #f9954a;
    --mode-shades-accent-shades-accent-recessive: #954911;
    --mode-shades-accent-shades-accent-extra-recessive: #63310c;
    --mode-shades-secondary-accent-shades-secondary-accent-subtle: #fafafa;
    --mode-shades-secondary-accent-shades-secondary-accent-extra-dominant: #000000;
    --mode-shades-secondary-accent-shades-secondary-accent-dominant: #2a292e;
    --mode-shades-secondary-accent-shades-secondary-accent-recessive: #6c6a70;
    --mode-shades-secondary-accent-shades-secondary-accent-extra-recessive: #807f85;
    --mode-shades-neutral-shades-100: #fafafa;
    --mode-shades-neutral-shades-200: #efeef1;
    --mode-shades-neutral-shades-300: #d9d8dd;
    --mode-shades-neutral-shades-400: #c8c7cd;
    --mode-shades-neutral-shades-500: #aeadb4;
    --mode-shades-neutral-shades-600: #807f85;
    --mode-shades-neutral-shades-700: #6c6a70;
    --mode-shades-neutral-shades-800: #2a292e;
    --mode-shades-neutral-shades-900: #000000;
    --mode-interaction-states-hover-background-hover: #fafafa;
    --mode-interaction-states-hover-background-subtle-hover: #efeef1;
    --mode-interaction-states-hover-text-hover: #151a20;
    --mode-interaction-states-hover-text-dominant-hover: #f9954a;
    --mode-interaction-states-hover-text-recessive-hover: #f9954a;
    --mode-interaction-states-hover-text-link-hover: #f9954a;
    --mode-interaction-states-hover-accent-hover: #f9954a;
    --mode-interaction-states-hover-text-on-accent-hover: #ffffff;
    --mode-interaction-states-hover-accent-secondary-hover: #000000;
    --mode-interaction-states-hover-text-on-accent-secondary-hover: #ffffff;
    --mode-interaction-states-hover-stroke-hover: #f9954a;
    --mode-interaction-states-hover-stroke-subtle-hover: #d8dde9;
    --mode-interaction-states-pressed-background-pressed: #fef2e8;
    --mode-interaction-states-pressed-background-subtle-pressed: #d9d8dd;
    --mode-interaction-states-pressed-text-pressed: #151a20;
    --mode-interaction-states-pressed-text-dominant-pressed: #fbaf77;
    --mode-interaction-states-pressed-text-recessive-pressed: #fbaf77;
    --mode-interaction-states-pressed-text-link-pressed: #fbaf77;
    --mode-interaction-states-pressed-accent-pressed: #fbaf77;
    --mode-interaction-states-pressed-text-on-accent-pressed: #ffffff;
    --mode-interaction-states-pressed-accent-secondary-pressed: #2a292e;
    --mode-interaction-states-pressed-text-on-accent-secondary-pressed: #ffffff;
    --mode-interaction-states-pressed-stroke-pressed: #fbaf77;
    --mode-interaction-states-pressed-stroke-subtle-pressed: #efeef1;
    --mode-interaction-states-focus-focus: #fafafa;
    --mode-interaction-states-focus-stroke-active: #f87a1d;
    --mode-interaction-states-disabled-background-disabled: #ffffff;
    --mode-interaction-states-disabled-background-subtle-disabled: #fafafa;
    --mode-interaction-states-disabled-text-disabled: #807f85;
    --mode-interaction-states-disabled-text-dominant-disabled: #fbaf77;
    --mode-interaction-states-disabled-text-recessive-disabled: #aeadb4;
    --mode-interaction-states-disabled-text-link-disabled: #fbaf77;
    --mode-interaction-states-disabled-accent-disabled: #fbaf77;
    --mode-interaction-states-disabled-text-on-accent-disabled: #ffffff;
    --mode-interaction-states-disabled-accent-secondary-disabled: #c8c7cd;
    --mode-interaction-states-disabled-text-on-accent-secondary-disabled: #ffffff;
    --mode-interaction-states-disabled-stroke-disabled: #fbaf77;
    --mode-interaction-states-disabled-stroke-subtle-disabled: #efeef1;
    --mode-interaction-states-visited-text-visited: #fafafa;
    --mode-interaction-states-visited-text-link-visited: #f87a1d;
    --mode-functional-notification-notification: #0c9ce2;
    --mode-functional-notification-text-on-notification: #e7f5fc;
    --mode-functional-informational-standard: #8f83ff;
    --mode-functional-informational-subtle: #d2cdff;
    --mode-functional-informational-hover: #a59cff;
    --mode-functional-informational-pressed: #3f349c;
    --mode-functional-success-standard: #00b381;
    --mode-functional-success-subtle: #c6f7e9;
    --mode-functional-success-hover: #2cc298;
    --mode-functional-success-pressed: #004834;
    --mode-functional-warning-standard: #f3c40a;
    --mode-functional-warning-subtle: #fef9e7;
    --mode-functional-warning-hover: #f8dc6c;
    --mode-functional-warning-pressed: #614e04;
    --mode-functional-destructive-standard: #cc0940;
    --mode-functional-destructive-subtle: #f5cdd8;
    --mode-functional-destructive-hover: #e06b8c;
    --mode-functional-destructive-pressed: #52041a;
    --mode-elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
    --mode-elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
    --mode-elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
    --mode-elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
    --mode-elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
    --mode-elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
    --mode-inverted-basic-background: #151a20;
    --mode-inverted-basic-background-subtle: #303540;
    --mode-inverted-basic-text: #efeef1;
    --mode-inverted-basic-text-dominant: #f87a1d;
    --mode-inverted-basic-text-recessive: #c8c7cd;
    --mode-inverted-basic-text-link: #f87a1d;
    --mode-inverted-basic-accent: #f87a1d;
    --mode-inverted-basic-text-on-accent: #151a20;
    --mode-inverted-basic-accent-secondary: #ffffff;
    --mode-inverted-basic-text-on-secondary-accent: #151a20;
    --mode-inverted-basic-stroke: #f87a1d;
    --mode-inverted-basic-stroke-subtle: #4c5160;
    --mode-inverted-basic-modal-overlay: rgba(0, 0, 0, 0.8);
    --mode-inverted-shades-accent-shades-accent-subtle: #fef2e8;
    --mode-inverted-shades-accent-shades-accent-extra-dominant: #fbaf77;
    --mode-inverted-shades-accent-shades-accent-dominant: #f9954a;
    --mode-inverted-shades-accent-shades-accent-recessive: #954911;
    --mode-inverted-shades-accent-shades-accent-extra-recessive: #63310c;
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-subtle: #2a292e;
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-extra-dominant: #fafafa;
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-dominant: #efeef1;
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-recessive: #d9d8dd;
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-extra-recessive: #c8c7cd;
    --mode-inverted-shades-neutral-shades-100: #151a20;
    --mode-inverted-shades-neutral-shades-200: #1f2229;
    --mode-inverted-shades-neutral-shades-300: #303540;
    --mode-inverted-shades-neutral-shades-400: #4c5160;
    --mode-inverted-shades-neutral-shades-500: #757c8f;
    --mode-inverted-shades-neutral-shades-600: #838a9f;
    --mode-inverted-shades-neutral-shades-700: #9099af;
    --mode-inverted-shades-neutral-shades-800: #d8dde9;
    --mode-inverted-shades-neutral-shades-900: #f2f1f8;
    --mode-inverted-interaction-states-hover-background-hover: #190c03;
    --mode-inverted-interaction-states-hover-background-subtle-hover: #6c6a70;
    --mode-inverted-interaction-states-hover-text-hover: #ffffff;
    --mode-inverted-interaction-states-hover-text-dominant-hover: #954911;
    --mode-inverted-interaction-states-hover-text-recessive-hover: #954911;
    --mode-inverted-interaction-states-hover-text-link-hover: #954911;
    --mode-inverted-interaction-states-hover-accent-hover: #954911;
    --mode-inverted-interaction-states-hover-text-on-accent-hover: #151a20;
    --mode-inverted-interaction-states-hover-accent-secondary-hover: #fafafa;
    --mode-inverted-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
    --mode-inverted-interaction-states-hover-stroke-hover: #954911;
    --mode-inverted-interaction-states-hover-stroke-subtle-hover: #303540;
    --mode-inverted-interaction-states-pressed-background-pressed: #6c6a70;
    --mode-inverted-interaction-states-pressed-background-subtle-pressed: #807f85;
    --mode-inverted-interaction-states-pressed-text-pressed: #ffffff;
    --mode-inverted-interaction-states-pressed-text-dominant-pressed: #f9954a;
    --mode-inverted-interaction-states-pressed-text-recessive-pressed: #f9954a;
    --mode-inverted-interaction-states-pressed-text-link-pressed: #f9954a;
    --mode-inverted-interaction-states-pressed-accent-pressed: #f9954a;
    --mode-inverted-interaction-states-pressed-text-on-accent-pressed: #151a20;
    --mode-inverted-interaction-states-pressed-accent-secondary-pressed: #efeef1;
    --mode-inverted-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
    --mode-inverted-interaction-states-pressed-stroke-pressed: #f9954a;
    --mode-inverted-interaction-states-pressed-stroke-subtle-pressed: #303540;
    --mode-inverted-interaction-states-focus-focus: #1f2229;
    --mode-inverted-interaction-states-focus-stroke-active: #f87a1d;
    --mode-inverted-interaction-states-disabled-background-disabled: #1f2229;
    --mode-inverted-interaction-states-disabled-background-subtle-disabled: #4c5160;
    --mode-inverted-interaction-states-disabled-text-disabled: #aeadb4;
    --mode-inverted-interaction-states-disabled-text-dominant-disabled: #954911;
    --mode-inverted-interaction-states-disabled-text-recessive-disabled: #807f85;
    --mode-inverted-interaction-states-disabled-text-link-disabled: #954911;
    --mode-inverted-interaction-states-disabled-accent-disabled: #954911;
    --mode-inverted-interaction-states-disabled-text-on-accent-disabled: #151a20;
    --mode-inverted-interaction-states-disabled-accent-secondary-disabled: #2a292e;
    --mode-inverted-interaction-states-disabled-text-on-accent-secondary-disabled: #151a20;
    --mode-inverted-interaction-states-disabled-stroke-disabled: #63310c;
    --mode-inverted-interaction-states-disabled-stroke-subtle-disabled: #6c6a70;
    --mode-inverted-interaction-states-visited-text-visited: #1f2229;
    --mode-inverted-interaction-states-visited-text-link-visited: #f87a1d;
    --mode-inverted-functional-notification-notification: #3db0e8;
    --mode-inverted-functional-notification-text-on-notification: #151a20;
    --mode-inverted-functional-informational-standard: #a59cff;
    --mode-inverted-functional-informational-subtle: #d2cdff;
    --mode-inverted-functional-informational-hover: #a59cff;
    --mode-inverted-functional-informational-pressed: #3f349c;
    --mode-inverted-functional-success-standard: #2cc298;
    --mode-inverted-functional-success-subtle: #c6f7e9;
    --mode-inverted-functional-success-hover: #2cc298;
    --mode-inverted-functional-success-pressed: #004834;
    --mode-inverted-functional-warning-standard: #f5d03b;
    --mode-inverted-functional-warning-subtle: #fef9e7;
    --mode-inverted-functional-warning-hover: #f8dc6c;
    --mode-inverted-functional-warning-pressed: #614e04;
    --mode-inverted-functional-destructive-standard: #d63a66;
    --mode-inverted-functional-destructive-subtle: #f5cdd8;
    --mode-inverted-functional-destructive-hover: #e06b8c;
    --mode-inverted-functional-destructive-pressed: #52041a;
    --mode-inverted-elevation-level-1: 0 1px 0 0 rgba(0, 0, 0, 0.5);
    --mode-inverted-elevation-level-2: 0 1px 4px 0 rgba(0, 0, 0, 0.5), 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    --mode-inverted-elevation-level-3: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    --mode-inverted-elevation-level-4: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
    --mode-inverted-elevation-level-5: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    --mode-inverted-elevation-level-6: 0 12px 40px 0 #000000a6;
  }
}
:root,
[data-scheme='neutral'] {
  --colours-basic-background: var(--mode-basic-background);
  --colours-basic-background-subtle: var(--mode-basic-background-subtle);
  --colours-basic-text: var(--mode-basic-text);
  --colours-basic-text-dominant: var(--mode-basic-text-dominant);
  --colours-basic-text-recessive: var(--mode-basic-text-recessive);
  --colours-basic-text-link: var(--mode-basic-text-link);
  --colours-basic-accent: var(--mode-basic-accent);
  --colours-basic-text-on-accent: var(--mode-basic-text-on-accent);
  --colours-basic-accent-secondary: var(--mode-basic-accent-secondary);
  --colours-basic-text-on-secondary-accent: var(--mode-basic-text-on-secondary-accent);
  --colours-basic-stroke: var(--mode-basic-stroke);
  --colours-basic-stroke-subtle: var(--mode-basic-stroke-subtle);
  --colours-basic-modal-overlay: var(--mode-basic-modal-overlay);
  --colours-shades-accent-shades-accent-subtle: var(--mode-shades-accent-shades-accent-subtle);
  --colours-shades-accent-shades-accent-extra-dominant: var(--mode-shades-accent-shades-accent-extra-dominant);
  --colours-shades-accent-shades-accent-dominant: var(--mode-shades-accent-shades-accent-dominant);
  --colours-shades-accent-shades-accent-recessive: var(--mode-shades-accent-shades-accent-recessive);
  --colours-shades-accent-shades-accent-extra-recessive: var(--mode-shades-accent-shades-accent-extra-recessive);
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: var(
    --mode-shades-secondary-accent-shades-secondary-accent-subtle
  );
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: var(
    --mode-shades-secondary-accent-shades-secondary-accent-extra-dominant
  );
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: var(
    --mode-shades-secondary-accent-shades-secondary-accent-dominant
  );
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: var(
    --mode-shades-secondary-accent-shades-secondary-accent-recessive
  );
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: var(
    --mode-shades-secondary-accent-shades-secondary-accent-extra-recessive
  );
  --colours-shades-neutral-shades-100: var(--mode-shades-neutral-shades-100);
  --colours-shades-neutral-shades-200: var(--mode-shades-neutral-shades-200);
  --colours-shades-neutral-shades-300: var(--mode-shades-neutral-shades-300);
  --colours-shades-neutral-shades-400: var(--mode-shades-neutral-shades-400);
  --colours-shades-neutral-shades-500: var(--mode-shades-neutral-shades-500);
  --colours-shades-neutral-shades-600: var(--mode-shades-neutral-shades-600);
  --colours-shades-neutral-shades-700: var(--mode-shades-neutral-shades-700);
  --colours-shades-neutral-shades-800: var(--mode-shades-neutral-shades-800);
  --colours-shades-neutral-shades-900: var(--mode-shades-neutral-shades-900);
  --colours-interaction-states-hover-background-hover: var(--mode-interaction-states-hover-background-hover);
  --colours-interaction-states-hover-background-subtle-hover: var(
    --mode-interaction-states-hover-background-subtle-hover
  );
  --colours-interaction-states-hover-text-hover: var(--mode-interaction-states-hover-text-hover);
  --colours-interaction-states-hover-text-dominant-hover: var(--mode-interaction-states-hover-text-dominant-hover);
  --colours-interaction-states-hover-text-recessive-hover: var(--mode-interaction-states-hover-text-recessive-hover);
  --colours-interaction-states-hover-text-link-hover: var(--mode-interaction-states-hover-text-link-hover);
  --colours-interaction-states-hover-accent-hover: var(--mode-interaction-states-hover-accent-hover);
  --colours-interaction-states-hover-text-on-accent-hover: var(--mode-interaction-states-hover-text-on-accent-hover);
  --colours-interaction-states-hover-accent-secondary-hover: var(
    --mode-interaction-states-hover-accent-secondary-hover
  );
  --colours-interaction-states-hover-text-on-accent-secondary-hover: var(
    --mode-interaction-states-hover-text-on-accent-secondary-hover
  );
  --colours-interaction-states-hover-stroke-hover: var(--mode-interaction-states-hover-stroke-hover);
  --colours-interaction-states-hover-stroke-subtle-hover: var(--mode-interaction-states-hover-stroke-subtle-hover);
  --colours-interaction-states-pressed-background-pressed: var(--mode-interaction-states-pressed-background-pressed);
  --colours-interaction-states-pressed-background-subtle-pressed: var(
    --mode-interaction-states-pressed-background-subtle-pressed
  );
  --colours-interaction-states-pressed-text-pressed: var(--mode-interaction-states-pressed-text-pressed);
  --colours-interaction-states-pressed-text-dominant-pressed: var(
    --mode-interaction-states-pressed-text-dominant-pressed
  );
  --colours-interaction-states-pressed-text-recessive-pressed: var(
    --mode-interaction-states-pressed-text-recessive-pressed
  );
  --colours-interaction-states-pressed-text-link-pressed: var(--mode-interaction-states-pressed-text-link-pressed);
  --colours-interaction-states-pressed-accent-pressed: var(--mode-interaction-states-pressed-accent-pressed);
  --colours-interaction-states-pressed-text-on-accent-pressed: var(
    --mode-interaction-states-pressed-text-on-accent-pressed
  );
  --colours-interaction-states-pressed-accent-secondary-pressed: var(
    --mode-interaction-states-pressed-accent-secondary-pressed
  );
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: var(
    --mode-interaction-states-pressed-text-on-accent-secondary-pressed
  );
  --colours-interaction-states-pressed-stroke-pressed: var(--mode-interaction-states-pressed-stroke-pressed);
  --colours-interaction-states-pressed-stroke-subtle-pressed: var(
    --mode-interaction-states-pressed-stroke-subtle-pressed
  );
  --colours-interaction-states-focus-focus: var(--mode-interaction-states-focus-focus);
  --colours-interaction-states-focus-stroke-active: var(--mode-interaction-states-focus-stroke-active);
  --colours-interaction-states-disabled-background-disabled: var(
    --mode-interaction-states-disabled-background-disabled
  );
  --colours-interaction-states-disabled-background-subtle-disabled: var(
    --mode-interaction-states-disabled-background-subtle-disabled
  );
  --colours-interaction-states-disabled-text-disabled: var(--mode-interaction-states-disabled-text-disabled);
  --colours-interaction-states-disabled-text-dominant-disabled: var(
    --mode-interaction-states-disabled-text-dominant-disabled
  );
  --colours-interaction-states-disabled-text-recessive-disabled: var(
    --mode-interaction-states-disabled-text-recessive-disabled
  );
  --colours-interaction-states-disabled-text-link-disabled: var(--mode-interaction-states-disabled-text-link-disabled);
  --colours-interaction-states-disabled-accent-disabled: var(--mode-interaction-states-disabled-accent-disabled);
  --colours-interaction-states-disabled-text-on-accent-disabled: var(
    --mode-interaction-states-disabled-text-on-accent-disabled
  );
  --colours-interaction-states-disabled-accent-secondary-disabled: var(
    --mode-interaction-states-disabled-accent-secondary-disabled
  );
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: var(
    --mode-interaction-states-disabled-text-on-accent-secondary-disabled
  );
  --colours-interaction-states-disabled-stroke-disabled: var(--mode-interaction-states-disabled-stroke-disabled);
  --colours-interaction-states-disabled-stroke-subtle-disabled: var(
    --mode-interaction-states-disabled-stroke-subtle-disabled
  );
  --colours-interaction-states-visited-text-visited: var(--mode-interaction-states-visited-text-visited);
  --colours-interaction-states-visited-text-link-visited: var(--mode-interaction-states-visited-text-link-visited);
  --colours-functional-notification-notification: var(--mode-functional-notification-notification);
  --colours-functional-notification-text-on-notification: var(--mode-functional-notification-text-on-notification);
  --colours-functional-informational-standard: var(--mode-functional-informational-standard);
  --colours-functional-informational-subtle: var(--mode-functional-informational-subtle);
  --colours-functional-informational-hover: var(--mode-functional-informational-hover);
  --colours-functional-informational-pressed: var(--mode-functional-informational-pressed);
  --colours-functional-success-standard: var(--mode-functional-success-standard);
  --colours-functional-success-subtle: var(--mode-functional-success-subtle);
  --colours-functional-success-hover: var(--mode-functional-success-hover);
  --colours-functional-success-pressed: var(--mode-functional-success-pressed);
  --colours-functional-warning-standard: var(--mode-functional-warning-standard);
  --colours-functional-warning-subtle: var(--mode-functional-warning-subtle);
  --colours-functional-warning-hover: var(--mode-functional-warning-hover);
  --colours-functional-warning-pressed: var(--mode-functional-warning-pressed);
  --colours-functional-destructive-standard: var(--mode-functional-destructive-standard);
  --colours-functional-destructive-subtle: var(--mode-functional-destructive-subtle);
  --colours-functional-destructive-hover: var(--mode-functional-destructive-hover);
  --colours-functional-destructive-pressed: var(--mode-functional-destructive-pressed);
  --elevation-level-1: var(--mode-elevation-level-1);
  --elevation-level-2: var(--mode-elevation-level-2);
  --elevation-level-3: var(--mode-elevation-level-3);
  --elevation-level-4: var(--mode-elevation-level-4);
  --elevation-level-5: var(--mode-elevation-level-5);
  --elevation-level-6: var(--mode-elevation-level-6);
}
[data-scheme='inverted'] {
  --colours-basic-background: var(--mode-inverted-basic-background);
  --colours-basic-background-subtle: var(--mode-inverted-basic-background-subtle);
  --colours-basic-text: var(--mode-inverted-basic-text);
  --colours-basic-text-dominant: var(--mode-inverted-basic-text-dominant);
  --colours-basic-text-recessive: var(--mode-inverted-basic-text-recessive);
  --colours-basic-text-link: var(--mode-inverted-basic-text-link);
  --colours-basic-accent: var(--mode-inverted-basic-accent);
  --colours-basic-text-on-accent: var(--mode-inverted-basic-text-on-accent);
  --colours-basic-accent-secondary: var(--mode-inverted-basic-accent-secondary);
  --colours-basic-text-on-secondary-accent: var(--mode-inverted-basic-text-on-secondary-accent);
  --colours-basic-stroke: var(--mode-inverted-basic-stroke);
  --colours-basic-stroke-subtle: var(--mode-inverted-basic-stroke-subtle);
  --colours-basic-modal-overlay: var(--mode-inverted-basic-modal-overlay);
  --colours-shades-accent-shades-accent-subtle: var(--mode-inverted-shades-accent-shades-accent-subtle);
  --colours-shades-accent-shades-accent-extra-dominant: var(--mode-inverted-shades-accent-shades-accent-extra-dominant);
  --colours-shades-accent-shades-accent-dominant: var(--mode-inverted-shades-accent-shades-accent-dominant);
  --colours-shades-accent-shades-accent-recessive: var(--mode-inverted-shades-accent-shades-accent-recessive);
  --colours-shades-accent-shades-accent-extra-recessive: var(
    --mode-inverted-shades-accent-shades-accent-extra-recessive
  );
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: var(
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-subtle
  );
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: var(
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-extra-dominant
  );
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: var(
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-dominant
  );
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: var(
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-recessive
  );
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: var(
    --mode-inverted-shades-secondary-accent-shades-secondary-accent-extra-recessive
  );
  --colours-shades-neutral-shades-100: var(--mode-inverted-shades-neutral-shades-100);
  --colours-shades-neutral-shades-200: var(--mode-inverted-shades-neutral-shades-200);
  --colours-shades-neutral-shades-300: var(--mode-inverted-shades-neutral-shades-300);
  --colours-shades-neutral-shades-400: var(--mode-inverted-shades-neutral-shades-400);
  --colours-shades-neutral-shades-500: var(--mode-inverted-shades-neutral-shades-500);
  --colours-shades-neutral-shades-600: var(--mode-inverted-shades-neutral-shades-600);
  --colours-shades-neutral-shades-700: var(--mode-inverted-shades-neutral-shades-700);
  --colours-shades-neutral-shades-800: var(--mode-inverted-shades-neutral-shades-800);
  --colours-shades-neutral-shades-900: var(--mode-inverted-shades-neutral-shades-900);
  --colours-interaction-states-hover-background-hover: var(--mode-inverted-interaction-states-hover-background-hover);
  --colours-interaction-states-hover-background-subtle-hover: var(
    --mode-inverted-interaction-states-hover-background-subtle-hover
  );
  --colours-interaction-states-hover-text-hover: var(--mode-inverted-interaction-states-hover-text-hover);
  --colours-interaction-states-hover-text-dominant-hover: var(
    --mode-inverted-interaction-states-hover-text-dominant-hover
  );
  --colours-interaction-states-hover-text-recessive-hover: var(
    --mode-inverted-interaction-states-hover-text-recessive-hover
  );
  --colours-interaction-states-hover-text-link-hover: var(--mode-inverted-interaction-states-hover-text-link-hover);
  --colours-interaction-states-hover-accent-hover: var(--mode-inverted-interaction-states-hover-accent-hover);
  --colours-interaction-states-hover-text-on-accent-hover: var(
    --mode-inverted-interaction-states-hover-text-on-accent-hover
  );
  --colours-interaction-states-hover-accent-secondary-hover: var(
    --mode-inverted-interaction-states-hover-accent-secondary-hover
  );
  --colours-interaction-states-hover-text-on-accent-secondary-hover: var(
    --mode-inverted-interaction-states-hover-text-on-accent-secondary-hover
  );
  --colours-interaction-states-hover-stroke-hover: var(--mode-inverted-interaction-states-hover-stroke-hover);
  --colours-interaction-states-hover-stroke-subtle-hover: var(
    --mode-inverted-interaction-states-hover-stroke-subtle-hover
  );
  --colours-interaction-states-pressed-background-pressed: var(
    --mode-inverted-interaction-states-pressed-background-pressed
  );
  --colours-interaction-states-pressed-background-subtle-pressed: var(
    --mode-inverted-interaction-states-pressed-background-subtle-pressed
  );
  --colours-interaction-states-pressed-text-pressed: var(--mode-inverted-interaction-states-pressed-text-pressed);
  --colours-interaction-states-pressed-text-dominant-pressed: var(
    --mode-inverted-interaction-states-pressed-text-dominant-pressed
  );
  --colours-interaction-states-pressed-text-recessive-pressed: var(
    --mode-inverted-interaction-states-pressed-text-recessive-pressed
  );
  --colours-interaction-states-pressed-text-link-pressed: var(
    --mode-inverted-interaction-states-pressed-text-link-pressed
  );
  --colours-interaction-states-pressed-accent-pressed: var(--mode-inverted-interaction-states-pressed-accent-pressed);
  --colours-interaction-states-pressed-text-on-accent-pressed: var(
    --mode-inverted-interaction-states-pressed-text-on-accent-pressed
  );
  --colours-interaction-states-pressed-accent-secondary-pressed: var(
    --mode-inverted-interaction-states-pressed-accent-secondary-pressed
  );
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: var(
    --mode-inverted-interaction-states-pressed-text-on-accent-secondary-pressed
  );
  --colours-interaction-states-pressed-stroke-pressed: var(--mode-inverted-interaction-states-pressed-stroke-pressed);
  --colours-interaction-states-pressed-stroke-subtle-pressed: var(
    --mode-inverted-interaction-states-pressed-stroke-subtle-pressed
  );
  --colours-interaction-states-focus-focus: var(--mode-inverted-interaction-states-focus-focus);
  --colours-interaction-states-focus-stroke-active: var(--mode-inverted-interaction-states-focus-stroke-active);
  --colours-interaction-states-disabled-background-disabled: var(
    --mode-inverted-interaction-states-disabled-background-disabled
  );
  --colours-interaction-states-disabled-background-subtle-disabled: var(
    --mode-inverted-interaction-states-disabled-background-subtle-disabled
  );
  --colours-interaction-states-disabled-text-disabled: var(--mode-inverted-interaction-states-disabled-text-disabled);
  --colours-interaction-states-disabled-text-dominant-disabled: var(
    --mode-inverted-interaction-states-disabled-text-dominant-disabled
  );
  --colours-interaction-states-disabled-text-recessive-disabled: var(
    --mode-inverted-interaction-states-disabled-text-recessive-disabled
  );
  --colours-interaction-states-disabled-text-link-disabled: var(
    --mode-inverted-interaction-states-disabled-text-link-disabled
  );
  --colours-interaction-states-disabled-accent-disabled: var(
    --mode-inverted-interaction-states-disabled-accent-disabled
  );
  --colours-interaction-states-disabled-text-on-accent-disabled: var(
    --mode-inverted-interaction-states-disabled-text-on-accent-disabled
  );
  --colours-interaction-states-disabled-accent-secondary-disabled: var(
    --mode-inverted-interaction-states-disabled-accent-secondary-disabled
  );
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: var(
    --mode-inverted-interaction-states-disabled-text-on-accent-secondary-disabled
  );
  --colours-interaction-states-disabled-stroke-disabled: var(
    --mode-inverted-interaction-states-disabled-stroke-disabled
  );
  --colours-interaction-states-disabled-stroke-subtle-disabled: var(
    --mode-inverted-interaction-states-disabled-stroke-subtle-disabled
  );
  --colours-interaction-states-visited-text-visited: var(--mode-inverted-interaction-states-visited-text-visited);
  --colours-interaction-states-visited-text-link-visited: var(
    --mode-inverted-interaction-states-visited-text-link-visited
  );
  --colours-functional-notification-notification: var(--mode-inverted-functional-notification-notification);
  --colours-functional-notification-text-on-notification: var(
    --mode-inverted-functional-notification-text-on-notification
  );
  --colours-functional-informational-standard: var(--mode-inverted-functional-informational-standard);
  --colours-functional-informational-subtle: var(--mode-inverted-functional-informational-subtle);
  --colours-functional-informational-hover: var(--mode-inverted-functional-informational-hover);
  --colours-functional-informational-pressed: var(--mode-inverted-functional-informational-pressed);
  --colours-functional-success-standard: var(--mode-inverted-functional-success-standard);
  --colours-functional-success-subtle: var(--mode-inverted-functional-success-subtle);
  --colours-functional-success-hover: var(--mode-inverted-functional-success-hover);
  --colours-functional-success-pressed: var(--mode-inverted-functional-success-pressed);
  --colours-functional-warning-standard: var(--mode-inverted-functional-warning-standard);
  --colours-functional-warning-subtle: var(--mode-inverted-functional-warning-subtle);
  --colours-functional-warning-hover: var(--mode-inverted-functional-warning-hover);
  --colours-functional-warning-pressed: var(--mode-inverted-functional-warning-pressed);
  --colours-functional-destructive-standard: var(--mode-inverted-functional-destructive-standard);
  --colours-functional-destructive-subtle: var(--mode-inverted-functional-destructive-subtle);
  --colours-functional-destructive-hover: var(--mode-inverted-functional-destructive-hover);
  --colours-functional-destructive-pressed: var(--mode-inverted-functional-destructive-pressed);
  --elevation-level-1: var(--mode-inverted-elevation-level-1);
  --elevation-level-2: var(--mode-inverted-elevation-level-2);
  --elevation-level-3: var(--mode-inverted-elevation-level-3);
  --elevation-level-4: var(--mode-inverted-elevation-level-4);
  --elevation-level-5: var(--mode-inverted-elevation-level-5);
  --elevation-level-6: var(--mode-inverted-elevation-level-6);
}
[data-scheme='white'] {
  --colours-basic-background: #ffffff;
  --colours-basic-background-subtle: #fafafa;
  --colours-basic-text: #2a292e;
  --colours-basic-text-dominant: #f87a1d;
  --colours-basic-text-recessive: #807f85;
  --colours-basic-text-link: #f87a1d;
  --colours-basic-accent: #f87a1d;
  --colours-basic-text-on-accent: #ffffff;
  --colours-basic-accent-secondary: #2a292e;
  --colours-basic-text-on-secondary-accent: #ffffff;
  --colours-basic-stroke: #f87a1d;
  --colours-basic-stroke-subtle: #efeef1;
  --colours-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-shades-accent-shades-accent-subtle: #fef2e8;
  --colours-shades-accent-shades-accent-extra-dominant: #fbaf77;
  --colours-shades-accent-shades-accent-dominant: #f9954a;
  --colours-shades-accent-shades-accent-recessive: #954911;
  --colours-shades-accent-shades-accent-extra-recessive: #63310c;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #fafafa;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #000000;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #2a292e;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #6c6a70;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #807f85;
  --colours-shades-neutral-shades-100: #fafafa;
  --colours-shades-neutral-shades-200: #efeef1;
  --colours-shades-neutral-shades-300: #d9d8dd;
  --colours-shades-neutral-shades-400: #c8c7cd;
  --colours-shades-neutral-shades-500: #aeadb4;
  --colours-shades-neutral-shades-600: #807f85;
  --colours-shades-neutral-shades-700: #6c6a70;
  --colours-shades-neutral-shades-800: #2a292e;
  --colours-shades-neutral-shades-900: #000000;
  --colours-interaction-states-hover-background-hover: #fafafa;
  --colours-interaction-states-hover-background-subtle-hover: #efeef1;
  --colours-interaction-states-hover-text-hover: #151a20;
  --colours-interaction-states-hover-text-dominant-hover: #f9954a;
  --colours-interaction-states-hover-text-recessive-hover: #f9954a;
  --colours-interaction-states-hover-text-link-hover: #f9954a;
  --colours-interaction-states-hover-accent-hover: #f9954a;
  --colours-interaction-states-hover-text-on-accent-hover: #ffffff;
  --colours-interaction-states-hover-accent-secondary-hover: #000000;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #ffffff;
  --colours-interaction-states-hover-stroke-hover: #f9954a;
  --colours-interaction-states-hover-stroke-subtle-hover: #d8dde9;
  --colours-interaction-states-pressed-background-pressed: #fef2e8;
  --colours-interaction-states-pressed-background-subtle-pressed: #d9d8dd;
  --colours-interaction-states-pressed-text-pressed: #151a20;
  --colours-interaction-states-pressed-text-dominant-pressed: #fbaf77;
  --colours-interaction-states-pressed-text-recessive-pressed: #fbaf77;
  --colours-interaction-states-pressed-text-link-pressed: #fbaf77;
  --colours-interaction-states-pressed-accent-pressed: #fbaf77;
  --colours-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --colours-interaction-states-pressed-accent-secondary-pressed: #2a292e;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #ffffff;
  --colours-interaction-states-pressed-stroke-pressed: #fbaf77;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #efeef1;
  --colours-interaction-states-focus-focus: #fafafa;
  --colours-interaction-states-focus-stroke-active: #f87a1d;
  --colours-interaction-states-disabled-background-disabled: #ffffff;
  --colours-interaction-states-disabled-background-subtle-disabled: #fafafa;
  --colours-interaction-states-disabled-text-disabled: #efeef1;
  --colours-interaction-states-disabled-text-dominant-disabled: #fbaf77;
  --colours-interaction-states-disabled-text-recessive-disabled: #d9d8dd;
  --colours-interaction-states-disabled-text-link-disabled: #fbaf77;
  --colours-interaction-states-disabled-accent-disabled: #fbaf77;
  --colours-interaction-states-disabled-text-on-accent-disabled: #ffffff;
  --colours-interaction-states-disabled-accent-secondary-disabled: #c8c7cd;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #ffffff;
  --colours-interaction-states-disabled-stroke-disabled: #fbaf77;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #efeef1;
  --colours-interaction-states-visited-text-visited: #fafafa;
  --colours-interaction-states-visited-text-link-visited: #f87a1d;
  --colours-functional-notification-notification: #0c9ce2;
  --colours-functional-notification-text-on-notification: #e7f5fc;
  --colours-functional-informational-standard: #8f83ff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #00b381;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f3c40a;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #cc0940;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
[data-scheme='black'] {
  --colours-basic-background: #151a20;
  --colours-basic-background-subtle: #303540;
  --colours-basic-text: #efeef1;
  --colours-basic-text-dominant: #f87a1d;
  --colours-basic-text-recessive: #c8c7cd;
  --colours-basic-text-link: #f87a1d;
  --colours-basic-accent: #f87a1d;
  --colours-basic-text-on-accent: #151a20;
  --colours-basic-accent-secondary: #ffffff;
  --colours-basic-text-on-secondary-accent: #151a20;
  --colours-basic-stroke: #f87a1d;
  --colours-basic-stroke-subtle: #4c5160;
  --colours-basic-modal-overlay: rgba(0, 0, 0, 0.8);
  --colours-shades-accent-shades-accent-subtle: #fef2e8;
  --colours-shades-accent-shades-accent-extra-dominant: #fbaf77;
  --colours-shades-accent-shades-accent-dominant: #f9954a;
  --colours-shades-accent-shades-accent-recessive: #954911;
  --colours-shades-accent-shades-accent-extra-recessive: #63310c;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #2a292e;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #fafafa;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #efeef1;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #d9d8dd;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #c8c7cd;
  --colours-shades-neutral-shades-100: #151a20;
  --colours-shades-neutral-shades-200: #1f2229;
  --colours-shades-neutral-shades-300: #303540;
  --colours-shades-neutral-shades-400: #4c5160;
  --colours-shades-neutral-shades-500: #757c8f;
  --colours-shades-neutral-shades-600: #838a9f;
  --colours-shades-neutral-shades-700: #9099af;
  --colours-shades-neutral-shades-800: #d8dde9;
  --colours-shades-neutral-shades-900: #f2f1f8;
  --colours-interaction-states-hover-background-hover: #190c03;
  --colours-interaction-states-hover-background-subtle-hover: #6c6a70;
  --colours-interaction-states-hover-text-hover: #ffffff;
  --colours-interaction-states-hover-text-dominant-hover: #954911;
  --colours-interaction-states-hover-text-recessive-hover: #954911;
  --colours-interaction-states-hover-text-link-hover: #954911;
  --colours-interaction-states-hover-accent-hover: #954911;
  --colours-interaction-states-hover-text-on-accent-hover: #151a20;
  --colours-interaction-states-hover-accent-secondary-hover: #fafafa;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --colours-interaction-states-hover-stroke-hover: #954911;
  --colours-interaction-states-hover-stroke-subtle-hover: #303540;
  --colours-interaction-states-pressed-background-pressed: #6c6a70;
  --colours-interaction-states-pressed-background-subtle-pressed: #807f85;
  --colours-interaction-states-pressed-text-pressed: #ffffff;
  --colours-interaction-states-pressed-text-dominant-pressed: #f9954a;
  --colours-interaction-states-pressed-text-recessive-pressed: #f9954a;
  --colours-interaction-states-pressed-text-link-pressed: #f9954a;
  --colours-interaction-states-pressed-accent-pressed: #f9954a;
  --colours-interaction-states-pressed-text-on-accent-pressed: #151a20;
  --colours-interaction-states-pressed-accent-secondary-pressed: #efeef1;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --colours-interaction-states-pressed-stroke-pressed: #f9954a;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #303540;
  --colours-interaction-states-focus-focus: #1f2229;
  --colours-interaction-states-focus-stroke-active: #f87a1d;
  --colours-interaction-states-disabled-background-disabled: #1f2229;
  --colours-interaction-states-disabled-background-subtle-disabled: #4c5160;
  --colours-interaction-states-disabled-text-disabled: #6c6a70;
  --colours-interaction-states-disabled-text-dominant-disabled: #954911;
  --colours-interaction-states-disabled-text-recessive-disabled: #807f85;
  --colours-interaction-states-disabled-text-link-disabled: #954911;
  --colours-interaction-states-disabled-accent-disabled: #954911;
  --colours-interaction-states-disabled-text-on-accent-disabled: #151a20;
  --colours-interaction-states-disabled-accent-secondary-disabled: #2a292e;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #151a20;
  --colours-interaction-states-disabled-stroke-disabled: #63310c;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #6c6a70;
  --colours-interaction-states-visited-text-visited: #1f2229;
  --colours-interaction-states-visited-text-link-visited: #f87a1d;
  --colours-functional-notification-notification: #3db0e8;
  --colours-functional-notification-text-on-notification: #151a20;
  --colours-functional-informational-standard: #a59cff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #2cc298;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f5d03b;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #d63a66;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(0, 0, 0, 0.5);
  --elevation-level-2: 0 1px 4px 0 rgba(0, 0, 0, 0.5), 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
  --elevation-level-3: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
  --elevation-level-4: 0 -1px 4px 0 rgba(0, 0, 0, 0.5);
  --elevation-level-5: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  --elevation-level-6: 0 12px 40px 0 #000000a6;
}
[data-scheme='brand'] {
  --colours-basic-background: #f87a1d;
  --colours-basic-background-subtle: #f9954a;
  --colours-basic-text: #ffffff;
  --colours-basic-text-dominant: #ffffff;
  --colours-basic-text-recessive: #fef2e8;
  --colours-basic-text-link: #ffffff;
  --colours-basic-accent: #ffffff;
  --colours-basic-text-on-accent: #151a20;
  --colours-basic-accent-secondary: #151a20;
  --colours-basic-text-on-secondary-accent: #ffffff;
  --colours-basic-stroke: #fdd7bb;
  --colours-basic-stroke-subtle: #fbaf77;
  --colours-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-shades-accent-shades-accent-subtle: #fef2e8;
  --colours-shades-accent-shades-accent-extra-dominant: #fdd7bb;
  --colours-shades-accent-shades-accent-dominant: #fbaf77;
  --colours-shades-accent-shades-accent-recessive: #954911;
  --colours-shades-accent-shades-accent-extra-recessive: #63310c;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #fef2e8;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #190c03;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #321806;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #63310c;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #954911;
  --colours-shades-neutral-shades-100: #fef2e8;
  --colours-shades-neutral-shades-200: #fdd7bb;
  --colours-shades-neutral-shades-300: #fbaf77;
  --colours-shades-neutral-shades-400: #f9954a;
  --colours-shades-neutral-shades-500: #f87a1d;
  --colours-shades-neutral-shades-600: #954911;
  --colours-shades-neutral-shades-700: #63310c;
  --colours-shades-neutral-shades-800: #321806;
  --colours-shades-neutral-shades-900: #190c03;
  --colours-interaction-states-hover-background-hover: #f9954a;
  --colours-interaction-states-hover-background-subtle-hover: #fbaf77;
  --colours-interaction-states-hover-text-hover: #fef2e8;
  --colours-interaction-states-hover-text-dominant-hover: #fef2e8;
  --colours-interaction-states-hover-text-recessive-hover: #fdd7bb;
  --colours-interaction-states-hover-text-link-hover: #fef2e8;
  --colours-interaction-states-hover-accent-hover: #fef2e8;
  --colours-interaction-states-hover-text-on-accent-hover: #151a20;
  --colours-interaction-states-hover-accent-secondary-hover: #190c03;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #ffffff;
  --colours-interaction-states-hover-stroke-hover: #fef2e8;
  --colours-interaction-states-hover-stroke-subtle-hover: #f9954a;
  --colours-interaction-states-pressed-background-pressed: #fbaf77;
  --colours-interaction-states-pressed-background-subtle-pressed: #fdd7bb;
  --colours-interaction-states-pressed-text-pressed: #fdd7bb;
  --colours-interaction-states-pressed-text-dominant-pressed: #fdd7bb;
  --colours-interaction-states-pressed-text-recessive-pressed: #fbaf77;
  --colours-interaction-states-pressed-text-link-pressed: #fdd7bb;
  --colours-interaction-states-pressed-accent-pressed: #fdd7bb;
  --colours-interaction-states-pressed-text-on-accent-pressed: #151a20;
  --colours-interaction-states-pressed-accent-secondary-pressed: #321806;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #ffffff;
  --colours-interaction-states-pressed-stroke-pressed: #ffffff;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #f9954a;
  --colours-interaction-states-focus-focus: #ffffff;
  --colours-interaction-states-focus-stroke-active: #fdd7bb;
  --colours-interaction-states-disabled-background-disabled: #954911;
  --colours-interaction-states-disabled-background-subtle-disabled: #954911;
  --colours-interaction-states-disabled-text-disabled: #63310c;
  --colours-interaction-states-disabled-text-dominant-disabled: #63310c;
  --colours-interaction-states-disabled-text-recessive-disabled: #63310c;
  --colours-interaction-states-disabled-text-link-disabled: #63310c;
  --colours-interaction-states-disabled-accent-disabled: #fbaf77;
  --colours-interaction-states-disabled-text-on-accent-disabled: #63310c;
  --colours-interaction-states-disabled-accent-secondary-disabled: #63310c;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #fdd7bb;
  --colours-interaction-states-disabled-stroke-disabled: #fbaf77;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #f9954a;
  --colours-interaction-states-visited-text-visited: #ffffff;
  --colours-interaction-states-visited-text-link-visited: #321806;
  --colours-functional-notification-notification: #cc0940;
  --colours-functional-notification-text-on-notification: #ffffff;
  --colours-functional-informational-standard: #a59cff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #00b381;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f3c40a;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #cc0940;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
[data-scheme='yellow'] {
  --colours-basic-background: #f3c40a;
  --colours-basic-background-subtle: #f5d03b;
  --colours-basic-text: #ffffff;
  --colours-basic-text-dominant: #151a20;
  --colours-basic-text-recessive: #614e04;
  --colours-basic-text-link: #151a20;
  --colours-basic-accent: #151a20;
  --colours-basic-text-on-accent: #ffffff;
  --colours-basic-accent-secondary: #f8dc6c;
  --colours-basic-text-on-secondary-accent: #151a20;
  --colours-basic-stroke: #927606;
  --colours-basic-stroke-subtle: #c29d08;
  --colours-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-shades-accent-shades-accent-subtle: #fef9e7;
  --colours-shades-accent-shades-accent-extra-dominant: #fae79d;
  --colours-shades-accent-shades-accent-dominant: #f8dc6c;
  --colours-shades-accent-shades-accent-recessive: #c29d08;
  --colours-shades-accent-shades-accent-extra-recessive: #927606;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #fef9e7;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #312702;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #614e04;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #927606;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #c29d08;
  --colours-shades-neutral-shades-100: #fef9e7;
  --colours-shades-neutral-shades-200: #fae79d;
  --colours-shades-neutral-shades-300: #f8dc6c;
  --colours-shades-neutral-shades-400: #f5d03b;
  --colours-shades-neutral-shades-500: #f3c40a;
  --colours-shades-neutral-shades-600: #c29d08;
  --colours-shades-neutral-shades-700: #927606;
  --colours-shades-neutral-shades-800: #614e04;
  --colours-shades-neutral-shades-900: #312702;
  --colours-interaction-states-hover-background-hover: #f5d03b;
  --colours-interaction-states-hover-background-subtle-hover: #f8dc6c;
  --colours-interaction-states-hover-text-hover: #312702;
  --colours-interaction-states-hover-text-dominant-hover: #312702;
  --colours-interaction-states-hover-text-recessive-hover: #312702;
  --colours-interaction-states-hover-text-link-hover: #312702;
  --colours-interaction-states-hover-accent-hover: #312702;
  --colours-interaction-states-hover-text-on-accent-hover: #ffffff;
  --colours-interaction-states-hover-accent-secondary-hover: #fae79d;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --colours-interaction-states-hover-stroke-hover: #614e04;
  --colours-interaction-states-hover-stroke-subtle-hover: #927606;
  --colours-interaction-states-pressed-background-pressed: #f8dc6c;
  --colours-interaction-states-pressed-background-subtle-pressed: #fae79d;
  --colours-interaction-states-pressed-text-pressed: #614e04;
  --colours-interaction-states-pressed-text-dominant-pressed: #614e04;
  --colours-interaction-states-pressed-text-recessive-pressed: #151a20;
  --colours-interaction-states-pressed-text-link-pressed: #614e04;
  --colours-interaction-states-pressed-accent-pressed: #614e04;
  --colours-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --colours-interaction-states-pressed-accent-secondary-pressed: #fef9e7;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --colours-interaction-states-pressed-stroke-pressed: #312702;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #614e04;
  --colours-interaction-states-focus-focus: #151a20;
  --colours-interaction-states-focus-stroke-active: #927606;
  --colours-interaction-states-disabled-background-disabled: #c29d08;
  --colours-interaction-states-disabled-background-subtle-disabled: #c29d08;
  --colours-interaction-states-disabled-text-disabled: #927606;
  --colours-interaction-states-disabled-text-dominant-disabled: #927606;
  --colours-interaction-states-disabled-text-recessive-disabled: #927606;
  --colours-interaction-states-disabled-text-link-disabled: #927606;
  --colours-interaction-states-disabled-accent-disabled: #f5d03b;
  --colours-interaction-states-disabled-text-on-accent-disabled: #927606;
  --colours-interaction-states-disabled-accent-secondary-disabled: #f5d03b;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #927606;
  --colours-interaction-states-disabled-stroke-disabled: #f8dc6c;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #f5d03b;
  --colours-interaction-states-visited-text-visited: #151a20;
  --colours-interaction-states-visited-text-link-visited: #927606;
  --colours-functional-notification-notification: #0c9ce2;
  --colours-functional-notification-text-on-notification: #ffffff;
  --colours-functional-informational-standard: #a59cff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #00b381;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f3c40a;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #cc0940;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
[data-scheme='mildorange'] {
  --colours-basic-background: #ff5e61;
  --colours-basic-background-subtle: #ff7e81;
  --colours-basic-text: #ffffff;
  --colours-basic-text-dominant: #151a20;
  --colours-basic-text-recessive: #5d1314;
  --colours-basic-text-link: #151a20;
  --colours-basic-accent: #151a20;
  --colours-basic-text-on-accent: #ffffff;
  --colours-basic-accent-secondary: #ffbfc0;
  --colours-basic-text-on-secondary-accent: #151a20;
  --colours-basic-stroke: #862627;
  --colours-basic-stroke-subtle: #d74b4e;
  --colours-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-shades-accent-shades-accent-subtle: #ffefef;
  --colours-shades-accent-shades-accent-extra-dominant: #ffdfdf;
  --colours-shades-accent-shades-accent-dominant: #ffbfc0;
  --colours-shades-accent-shades-accent-recessive: #d74b4e;
  --colours-shades-accent-shades-accent-extra-recessive: #862627;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #ffefef;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #350001;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #5d1314;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #862627;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #d74b4e;
  --colours-shades-neutral-shades-100: #ffefef;
  --colours-shades-neutral-shades-200: #ffdfdf;
  --colours-shades-neutral-shades-300: #ffbfc0;
  --colours-shades-neutral-shades-400: #ff7e81;
  --colours-shades-neutral-shades-500: #ff5e61;
  --colours-shades-neutral-shades-600: #d74b4e;
  --colours-shades-neutral-shades-700: #862627;
  --colours-shades-neutral-shades-800: #5d1314;
  --colours-shades-neutral-shades-900: #350001;
  --colours-interaction-states-hover-background-hover: #ff7e81;
  --colours-interaction-states-hover-background-subtle-hover: #ffbfc0;
  --colours-interaction-states-hover-text-hover: #350001;
  --colours-interaction-states-hover-text-dominant-hover: #350001;
  --colours-interaction-states-hover-text-recessive-hover: #350001;
  --colours-interaction-states-hover-text-link-hover: #350001;
  --colours-interaction-states-hover-accent-hover: #350001;
  --colours-interaction-states-hover-text-on-accent-hover: #ffffff;
  --colours-interaction-states-hover-accent-secondary-hover: #ffdfdf;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --colours-interaction-states-hover-stroke-hover: #5d1314;
  --colours-interaction-states-hover-stroke-subtle-hover: #862627;
  --colours-interaction-states-pressed-background-pressed: #ffbfc0;
  --colours-interaction-states-pressed-background-subtle-pressed: #ffdfdf;
  --colours-interaction-states-pressed-text-pressed: #5d1314;
  --colours-interaction-states-pressed-text-dominant-pressed: #5d1314;
  --colours-interaction-states-pressed-text-recessive-pressed: #151a20;
  --colours-interaction-states-pressed-text-link-pressed: #5d1314;
  --colours-interaction-states-pressed-accent-pressed: #5d1314;
  --colours-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --colours-interaction-states-pressed-accent-secondary-pressed: #ffefef;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --colours-interaction-states-pressed-stroke-pressed: #350001;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #5d1314;
  --colours-interaction-states-focus-focus: #151a20;
  --colours-interaction-states-focus-stroke-active: #862627;
  --colours-interaction-states-disabled-background-disabled: #d74b4e;
  --colours-interaction-states-disabled-background-subtle-disabled: #d74b4e;
  --colours-interaction-states-disabled-text-disabled: #862627;
  --colours-interaction-states-disabled-text-dominant-disabled: #862627;
  --colours-interaction-states-disabled-text-recessive-disabled: #862627;
  --colours-interaction-states-disabled-text-link-disabled: #862627;
  --colours-interaction-states-disabled-accent-disabled: #ff7e81;
  --colours-interaction-states-disabled-text-on-accent-disabled: #862627;
  --colours-interaction-states-disabled-accent-secondary-disabled: #ff7e81;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #862627;
  --colours-interaction-states-disabled-stroke-disabled: #ffbfc0;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #ff7e81;
  --colours-interaction-states-visited-text-visited: #151a20;
  --colours-interaction-states-visited-text-link-visited: #862627;
  --colours-functional-notification-notification: #0c9ce2;
  --colours-functional-notification-text-on-notification: #ffffff;
  --colours-functional-informational-standard: #a59cff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #00b381;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f3c40a;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #cc0940;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
[data-scheme='blue'] {
  --colours-basic-background: #0c9ce2;
  --colours-basic-background-subtle: #3db0e8;
  --colours-basic-text: #ffffff;
  --colours-basic-text-dominant: #151a20;
  --colours-basic-text-recessive: #021f2d;
  --colours-basic-text-link: #151a20;
  --colours-basic-accent: #151a20;
  --colours-basic-text-on-accent: #ffffff;
  --colours-basic-accent-secondary: #6dc4ee;
  --colours-basic-text-on-secondary-accent: #151a20;
  --colours-basic-stroke: #053e5a;
  --colours-basic-stroke-subtle: #0a7db5;
  --colours-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-shades-accent-shades-accent-subtle: #e7f5fc;
  --colours-shades-accent-shades-accent-extra-dominant: #9ed7f3;
  --colours-shades-accent-shades-accent-dominant: #6dc4ee;
  --colours-shades-accent-shades-accent-recessive: #0a7db5;
  --colours-shades-accent-shades-accent-extra-recessive: #053e5a;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #e7f5fc;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #010c12;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #021f2d;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #053e5a;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #0a7db5;
  --colours-shades-neutral-shades-100: #e7f5fc;
  --colours-shades-neutral-shades-200: #9ed7f3;
  --colours-shades-neutral-shades-300: #6dc4ee;
  --colours-shades-neutral-shades-400: #3db0e8;
  --colours-shades-neutral-shades-500: #0c9ce2;
  --colours-shades-neutral-shades-600: #0a7db5;
  --colours-shades-neutral-shades-700: #053e5a;
  --colours-shades-neutral-shades-800: #021f2d;
  --colours-shades-neutral-shades-900: #010c12;
  --colours-interaction-states-hover-background-hover: #3db0e8;
  --colours-interaction-states-hover-background-subtle-hover: #6dc4ee;
  --colours-interaction-states-hover-text-hover: #010c12;
  --colours-interaction-states-hover-text-dominant-hover: #010c12;
  --colours-interaction-states-hover-text-recessive-hover: #010c12;
  --colours-interaction-states-hover-text-link-hover: #010c12;
  --colours-interaction-states-hover-accent-hover: #010c12;
  --colours-interaction-states-hover-text-on-accent-hover: #ffffff;
  --colours-interaction-states-hover-accent-secondary-hover: #9ed7f3;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --colours-interaction-states-hover-stroke-hover: #021f2d;
  --colours-interaction-states-hover-stroke-subtle-hover: #053e5a;
  --colours-interaction-states-pressed-background-pressed: #6dc4ee;
  --colours-interaction-states-pressed-background-subtle-pressed: #9ed7f3;
  --colours-interaction-states-pressed-text-pressed: #021f2d;
  --colours-interaction-states-pressed-text-dominant-pressed: #021f2d;
  --colours-interaction-states-pressed-text-recessive-pressed: #151a20;
  --colours-interaction-states-pressed-text-link-pressed: #021f2d;
  --colours-interaction-states-pressed-accent-pressed: #021f2d;
  --colours-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --colours-interaction-states-pressed-accent-secondary-pressed: #e7f5fc;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --colours-interaction-states-pressed-stroke-pressed: #010c12;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #021f2d;
  --colours-interaction-states-focus-focus: #151a20;
  --colours-interaction-states-focus-stroke-active: #053e5a;
  --colours-interaction-states-disabled-background-disabled: #0a7db5;
  --colours-interaction-states-disabled-background-subtle-disabled: #0a7db5;
  --colours-interaction-states-disabled-text-disabled: #053e5a;
  --colours-interaction-states-disabled-text-dominant-disabled: #053e5a;
  --colours-interaction-states-disabled-text-recessive-disabled: #053e5a;
  --colours-interaction-states-disabled-text-link-disabled: #053e5a;
  --colours-interaction-states-disabled-accent-disabled: #3db0e8;
  --colours-interaction-states-disabled-text-on-accent-disabled: #053e5a;
  --colours-interaction-states-disabled-accent-secondary-disabled: #3db0e8;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #053e5a;
  --colours-interaction-states-disabled-stroke-disabled: #6dc4ee;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #3db0e8;
  --colours-interaction-states-visited-text-visited: #151a20;
  --colours-interaction-states-visited-text-link-visited: #053e5a;
  --colours-functional-notification-notification: #0c9ce2;
  --colours-functional-notification-text-on-notification: #ffffff;
  --colours-functional-informational-standard: #a59cff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #00b381;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f3c40a;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #cc0940;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
[data-scheme='lavender'] {
  --colours-basic-background: #8f83ff;
  --colours-basic-background-subtle: #a59cff;
  --colours-basic-text: #ffffff;
  --colours-basic-text-dominant: #151a20;
  --colours-basic-text-recessive: #31278c;
  --colours-basic-text-link: #151a20;
  --colours-basic-accent: #151a20;
  --colours-basic-text-on-accent: #ffffff;
  --colours-basic-accent-secondary: #bcb5ff;
  --colours-basic-text-on-secondary-accent: #151a20;
  --colours-basic-stroke: #3f349c;
  --colours-basic-stroke-subtle: #594fbd;
  --colours-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-shades-accent-shades-accent-subtle: #f4f3ff;
  --colours-shades-accent-shades-accent-extra-dominant: #dddaff;
  --colours-shades-accent-shades-accent-dominant: #bcb5ff;
  --colours-shades-accent-shades-accent-recessive: #594fbd;
  --colours-shades-accent-shades-accent-extra-recessive: #3f349c;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #f4f3ff;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #010c12;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #31278c;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #3f349c;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #594fbd;
  --colours-shades-neutral-shades-100: #f4f3ff;
  --colours-shades-neutral-shades-200: #dddaff;
  --colours-shades-neutral-shades-300: #bcb5ff;
  --colours-shades-neutral-shades-400: #a59cff;
  --colours-shades-neutral-shades-500: #8f83ff;
  --colours-shades-neutral-shades-600: #594fbd;
  --colours-shades-neutral-shades-700: #3f349c;
  --colours-shades-neutral-shades-800: #31278c;
  --colours-shades-neutral-shades-900: #160d6b;
  --colours-interaction-states-hover-background-hover: #a59cff;
  --colours-interaction-states-hover-background-subtle-hover: #bcb5ff;
  --colours-interaction-states-hover-text-hover: #160d6b;
  --colours-interaction-states-hover-text-dominant-hover: #160d6b;
  --colours-interaction-states-hover-text-recessive-hover: #160d6b;
  --colours-interaction-states-hover-text-link-hover: #160d6b;
  --colours-interaction-states-hover-accent-hover: #160d6b;
  --colours-interaction-states-hover-text-on-accent-hover: #ffffff;
  --colours-interaction-states-hover-accent-secondary-hover: #dddaff;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --colours-interaction-states-hover-stroke-hover: #31278c;
  --colours-interaction-states-hover-stroke-subtle-hover: #3f349c;
  --colours-interaction-states-pressed-background-pressed: #bcb5ff;
  --colours-interaction-states-pressed-background-subtle-pressed: #dddaff;
  --colours-interaction-states-pressed-text-pressed: #31278c;
  --colours-interaction-states-pressed-text-dominant-pressed: #31278c;
  --colours-interaction-states-pressed-text-recessive-pressed: #151a20;
  --colours-interaction-states-pressed-text-link-pressed: #31278c;
  --colours-interaction-states-pressed-accent-pressed: #31278c;
  --colours-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --colours-interaction-states-pressed-accent-secondary-pressed: #f4f3ff;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --colours-interaction-states-pressed-stroke-pressed: #160d6b;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #31278c;
  --colours-interaction-states-focus-focus: #151a20;
  --colours-interaction-states-focus-stroke-active: #3f349c;
  --colours-interaction-states-disabled-background-disabled: #594fbd;
  --colours-interaction-states-disabled-background-subtle-disabled: #594fbd;
  --colours-interaction-states-disabled-text-disabled: #3f349c;
  --colours-interaction-states-disabled-text-dominant-disabled: #3f349c;
  --colours-interaction-states-disabled-text-recessive-disabled: #3f349c;
  --colours-interaction-states-disabled-text-link-disabled: #3f349c;
  --colours-interaction-states-disabled-accent-disabled: #a59cff;
  --colours-interaction-states-disabled-text-on-accent-disabled: #3f349c;
  --colours-interaction-states-disabled-accent-secondary-disabled: #a59cff;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #3f349c;
  --colours-interaction-states-disabled-stroke-disabled: #bcb5ff;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #a59cff;
  --colours-interaction-states-visited-text-visited: #151a20;
  --colours-interaction-states-visited-text-link-visited: #3f349c;
  --colours-functional-notification-notification: #0c9ce2;
  --colours-functional-notification-text-on-notification: #ffffff;
  --colours-functional-informational-standard: #a59cff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #00b381;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f3c40a;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #cc0940;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
[data-scheme='green'] {
  --colours-basic-background: #00b381;
  --colours-basic-background-subtle: #2cc298;
  --colours-basic-text: #ffffff;
  --colours-basic-text-dominant: #151a20;
  --colours-basic-text-recessive: #00241a;
  --colours-basic-text-link: #151a20;
  --colours-basic-accent: #151a20;
  --colours-basic-text-on-accent: #ffffff;
  --colours-basic-accent-secondary: #84e1c7;
  --colours-basic-text-on-secondary-accent: #151a20;
  --colours-basic-stroke: #004834;
  --colours-basic-stroke-subtle: #008f67;
  --colours-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-shades-accent-shades-accent-subtle: #c6f7e9;
  --colours-shades-accent-shades-accent-extra-dominant: #b0f0de;
  --colours-shades-accent-shades-accent-dominant: #84e1c7;
  --colours-shades-accent-shades-accent-recessive: #008f67;
  --colours-shades-accent-shades-accent-extra-recessive: #004834;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #c6f7e9;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #00120d;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #00241a;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #004834;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #008f67;
  --colours-shades-neutral-shades-100: #c6f7e9;
  --colours-shades-neutral-shades-200: #b0f0de;
  --colours-shades-neutral-shades-300: #84e1c7;
  --colours-shades-neutral-shades-400: #2cc298;
  --colours-shades-neutral-shades-500: #00b381;
  --colours-shades-neutral-shades-600: #008f67;
  --colours-shades-neutral-shades-700: #004834;
  --colours-shades-neutral-shades-800: #00241a;
  --colours-shades-neutral-shades-900: #00120d;
  --colours-interaction-states-hover-background-hover: #2cc298;
  --colours-interaction-states-hover-background-subtle-hover: #84e1c7;
  --colours-interaction-states-hover-text-hover: #00120d;
  --colours-interaction-states-hover-text-dominant-hover: #00120d;
  --colours-interaction-states-hover-text-recessive-hover: #00120d;
  --colours-interaction-states-hover-text-link-hover: #00120d;
  --colours-interaction-states-hover-accent-hover: #00120d;
  --colours-interaction-states-hover-text-on-accent-hover: #ffffff;
  --colours-interaction-states-hover-accent-secondary-hover: #b0f0de;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --colours-interaction-states-hover-stroke-hover: #00241a;
  --colours-interaction-states-hover-stroke-subtle-hover: #004834;
  --colours-interaction-states-pressed-background-pressed: #84e1c7;
  --colours-interaction-states-pressed-background-subtle-pressed: #b0f0de;
  --colours-interaction-states-pressed-text-pressed: #00241a;
  --colours-interaction-states-pressed-text-dominant-pressed: #00241a;
  --colours-interaction-states-pressed-text-recessive-pressed: #151a20;
  --colours-interaction-states-pressed-text-link-pressed: #00241a;
  --colours-interaction-states-pressed-accent-pressed: #00241a;
  --colours-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --colours-interaction-states-pressed-accent-secondary-pressed: #c6f7e9;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --colours-interaction-states-pressed-stroke-pressed: #00120d;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #00241a;
  --colours-interaction-states-focus-focus: #151a20;
  --colours-interaction-states-focus-stroke-active: #004834;
  --colours-interaction-states-disabled-background-disabled: #b0f0de;
  --colours-interaction-states-disabled-background-subtle-disabled: #008f67;
  --colours-interaction-states-disabled-text-disabled: #004834;
  --colours-interaction-states-disabled-text-dominant-disabled: #004834;
  --colours-interaction-states-disabled-text-recessive-disabled: #004834;
  --colours-interaction-states-disabled-text-link-disabled: #004834;
  --colours-interaction-states-disabled-accent-disabled: #2cc298;
  --colours-interaction-states-disabled-text-on-accent-disabled: #004834;
  --colours-interaction-states-disabled-accent-secondary-disabled: #2cc298;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #004834;
  --colours-interaction-states-disabled-stroke-disabled: #84e1c7;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #2cc298;
  --colours-interaction-states-visited-text-visited: #151a20;
  --colours-interaction-states-visited-text-link-visited: #004834;
  --colours-functional-notification-notification: #0c9ce2;
  --colours-functional-notification-text-on-notification: #ffffff;
  --colours-functional-informational-standard: #a59cff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #00b381;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f3c40a;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #cc0940;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
[data-scheme='mildgreen'] {
  --colours-basic-background: #7bbdaa;
  --colours-basic-background-subtle: #95cabb;
  --colours-basic-text: #ffffff;
  --colours-basic-text-dominant: #151a20;
  --colours-basic-text-recessive: #345249;
  --colours-basic-text-link: #151a20;
  --colours-basic-accent: #151a20;
  --colours-basic-text-on-accent: #ffffff;
  --colours-basic-accent-secondary: #cae5dd;
  --colours-basic-text-on-secondary-accent: #151a20;
  --colours-basic-stroke: #4c766a;
  --colours-basic-stroke-subtle: #63998a;
  --colours-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-shades-accent-shades-accent-subtle: #e5f2ee;
  --colours-shades-accent-shades-accent-extra-dominant: #d7ebe6;
  --colours-shades-accent-shades-accent-dominant: #cae5dd;
  --colours-shades-accent-shades-accent-recessive: #63998a;
  --colours-shades-accent-shades-accent-extra-recessive: #4c766a;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #e5f2ee;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #1d2f29;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #345249;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #4c766a;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #63998a;
  --colours-shades-neutral-shades-100: #e5f2ee;
  --colours-shades-neutral-shades-200: #d7ebe6;
  --colours-shades-neutral-shades-300: #cae5dd;
  --colours-shades-neutral-shades-400: #95cabb;
  --colours-shades-neutral-shades-500: #7bbdaa;
  --colours-shades-neutral-shades-600: #63998a;
  --colours-shades-neutral-shades-700: #4c766a;
  --colours-shades-neutral-shades-800: #345249;
  --colours-shades-neutral-shades-900: #1d2f29;
  --colours-interaction-states-hover-background-hover: #95cabb;
  --colours-interaction-states-hover-background-subtle-hover: #cae5dd;
  --colours-interaction-states-hover-text-hover: #1d2f29;
  --colours-interaction-states-hover-text-dominant-hover: #1d2f29;
  --colours-interaction-states-hover-text-recessive-hover: #1d2f29;
  --colours-interaction-states-hover-text-link-hover: #1d2f29;
  --colours-interaction-states-hover-accent-hover: #1d2f29;
  --colours-interaction-states-hover-text-on-accent-hover: #ffffff;
  --colours-interaction-states-hover-accent-secondary-hover: #d7ebe6;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --colours-interaction-states-hover-stroke-hover: #345249;
  --colours-interaction-states-hover-stroke-subtle-hover: #4c766a;
  --colours-interaction-states-pressed-background-pressed: #cae5dd;
  --colours-interaction-states-pressed-background-subtle-pressed: #d7ebe6;
  --colours-interaction-states-pressed-text-pressed: #345249;
  --colours-interaction-states-pressed-text-dominant-pressed: #345249;
  --colours-interaction-states-pressed-text-recessive-pressed: #151a20;
  --colours-interaction-states-pressed-text-link-pressed: #345249;
  --colours-interaction-states-pressed-accent-pressed: #345249;
  --colours-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --colours-interaction-states-pressed-accent-secondary-pressed: #e5f2ee;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --colours-interaction-states-pressed-stroke-pressed: #1d2f29;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #345249;
  --colours-interaction-states-focus-focus: #151a20;
  --colours-interaction-states-focus-stroke-active: #4c766a;
  --colours-interaction-states-disabled-background-disabled: #95cabb;
  --colours-interaction-states-disabled-background-subtle-disabled: #63998a;
  --colours-interaction-states-disabled-text-disabled: #4c766a;
  --colours-interaction-states-disabled-text-dominant-disabled: #4c766a;
  --colours-interaction-states-disabled-text-recessive-disabled: #4c766a;
  --colours-interaction-states-disabled-text-link-disabled: #4c766a;
  --colours-interaction-states-disabled-accent-disabled: #95cabb;
  --colours-interaction-states-disabled-text-on-accent-disabled: #4c766a;
  --colours-interaction-states-disabled-accent-secondary-disabled: #95cabb;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #4c766a;
  --colours-interaction-states-disabled-stroke-disabled: #cae5dd;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #95cabb;
  --colours-interaction-states-visited-text-visited: #151a20;
  --colours-interaction-states-visited-text-link-visited: #4c766a;
  --colours-functional-notification-notification: #0c9ce2;
  --colours-functional-notification-text-on-notification: #ffffff;
  --colours-functional-informational-standard: #a59cff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #00b381;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f3c40a;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #cc0940;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
[data-scheme='mutedgreen'] {
  --colours-basic-background: #b3bdba;
  --colours-basic-background-subtle: #c2cac8;
  --colours-basic-text: #ffffff;
  --colours-basic-text-dominant: #ffffff;
  --colours-basic-text-recessive: #5c6361;
  --colours-basic-text-link: #151a20;
  --colours-basic-accent: #151a20;
  --colours-basic-text-on-accent: #ffffff;
  --colours-basic-accent-secondary: #d1d7d6;
  --colours-basic-text-on-secondary-accent: #151a20;
  --colours-basic-stroke: #79817e;
  --colours-basic-stroke-subtle: #969f9c;
  --colours-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-shades-accent-shades-accent-subtle: #f0f2f1;
  --colours-shades-accent-shades-accent-extra-dominant: #e1e5e3;
  --colours-shades-accent-shades-accent-dominant: #d1d7d6;
  --colours-shades-accent-shades-accent-recessive: #969f9c;
  --colours-shades-accent-shades-accent-extra-recessive: #79817e;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #f0f2f1;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #3f4543;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #5c6361;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #79817e;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #969f9c;
  --colours-shades-neutral-shades-100: #f0f2f1;
  --colours-shades-neutral-shades-200: #e1e5e3;
  --colours-shades-neutral-shades-300: #d1d7d6;
  --colours-shades-neutral-shades-400: #c2cac8;
  --colours-shades-neutral-shades-500: #b3bdba;
  --colours-shades-neutral-shades-600: #969f9c;
  --colours-shades-neutral-shades-700: #79817e;
  --colours-shades-neutral-shades-800: #5c6361;
  --colours-shades-neutral-shades-900: #3f4543;
  --colours-interaction-states-hover-background-hover: #c2cac8;
  --colours-interaction-states-hover-background-subtle-hover: #d1d7d6;
  --colours-interaction-states-hover-text-hover: #3f4543;
  --colours-interaction-states-hover-text-dominant-hover: #3f4543;
  --colours-interaction-states-hover-text-recessive-hover: #3f4543;
  --colours-interaction-states-hover-text-link-hover: #3f4543;
  --colours-interaction-states-hover-accent-hover: #3f4543;
  --colours-interaction-states-hover-text-on-accent-hover: #ffffff;
  --colours-interaction-states-hover-accent-secondary-hover: #e1e5e3;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --colours-interaction-states-hover-stroke-hover: #5c6361;
  --colours-interaction-states-hover-stroke-subtle-hover: #79817e;
  --colours-interaction-states-pressed-background-pressed: #d1d7d6;
  --colours-interaction-states-pressed-background-subtle-pressed: #e1e5e3;
  --colours-interaction-states-pressed-text-pressed: #5c6361;
  --colours-interaction-states-pressed-text-dominant-pressed: #5c6361;
  --colours-interaction-states-pressed-text-recessive-pressed: #151a20;
  --colours-interaction-states-pressed-text-link-pressed: #5c6361;
  --colours-interaction-states-pressed-accent-pressed: #5c6361;
  --colours-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --colours-interaction-states-pressed-accent-secondary-pressed: #f0f2f1;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --colours-interaction-states-pressed-stroke-pressed: #3f4543;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #5c6361;
  --colours-interaction-states-focus-focus: #151a20;
  --colours-interaction-states-focus-stroke-active: #79817e;
  --colours-interaction-states-disabled-background-disabled: #d1d7d6;
  --colours-interaction-states-disabled-background-subtle-disabled: #969f9c;
  --colours-interaction-states-disabled-text-disabled: #79817e;
  --colours-interaction-states-disabled-text-dominant-disabled: #79817e;
  --colours-interaction-states-disabled-text-recessive-disabled: #79817e;
  --colours-interaction-states-disabled-text-link-disabled: #79817e;
  --colours-interaction-states-disabled-accent-disabled: #c2cac8;
  --colours-interaction-states-disabled-text-on-accent-disabled: #79817e;
  --colours-interaction-states-disabled-accent-secondary-disabled: #c2cac8;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #79817e;
  --colours-interaction-states-disabled-stroke-disabled: #cae5dd;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #c2cac8;
  --colours-interaction-states-visited-text-visited: #151a20;
  --colours-interaction-states-visited-text-link-visited: #79817e;
  --colours-functional-notification-notification: #0c9ce2;
  --colours-functional-notification-text-on-notification: #ffffff;
  --colours-functional-informational-standard: #a59cff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #00b381;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f3c40a;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #cc0940;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
[data-scheme='What’s Up'] {
  --colours-up-scheme-basic-background: #1e1e1e;
  --colours-up-scheme-basic-background-subtle: #ffffff;
  --colours-up-scheme-basic-text: #1e1e1e;
  --colours-up-scheme-basic-text-dominant: #fff055;
  --colours-up-scheme-basic-text-recessive: #ffffff;
  --colours-up-scheme-basic-text-link: #1e1e1e;
  --colours-up-scheme-basic-accent: #fff055;
  --colours-up-scheme-basic-text-on-accent: #1e1e1e;
  --colours-up-scheme-basic-accent-secondary: #d1d7d6;
  --colours-up-scheme-basic-text-on-secondary-accent: #151a20;
  --colours-up-scheme-basic-stroke: #79817e;
  --colours-up-scheme-basic-stroke-subtle: #969f9c;
  --colours-up-scheme-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-up-scheme-shades-accent-shades-accent-subtle: #f0f2f1;
  --colours-up-scheme-shades-accent-shades-accent-extra-dominant: #e1e5e3;
  --colours-up-scheme-shades-accent-shades-accent-dominant: #d1d7d6;
  --colours-up-scheme-shades-accent-shades-accent-recessive: #969f9c;
  --colours-up-scheme-shades-accent-shades-accent-extra-recessive: #79817e;
  --colours-up-scheme-shades-secondary-accent-shades-secondary-accent-subtle: #f0f2f1;
  --colours-up-scheme-shades-secondary-accent-shades-secondary-accent-extra-dominant: #3f4543;
  --colours-up-scheme-shades-secondary-accent-shades-secondary-accent-dominant: #5c6361;
  --colours-up-scheme-shades-secondary-accent-shades-secondary-accent-recessive: #79817e;
  --colours-up-scheme-shades-secondary-accent-shades-secondary-accent-extra-recessive: #969f9c;
  --colours-up-scheme-shades-neutral-shades-100: #f0f2f1;
  --colours-up-scheme-shades-neutral-shades-200: #e1e5e3;
  --colours-up-scheme-shades-neutral-shades-300: #d1d7d6;
  --colours-up-scheme-shades-neutral-shades-400: #c2cac8;
  --colours-up-scheme-shades-neutral-shades-500: #b3bdba;
  --colours-up-scheme-shades-neutral-shades-600: #969f9c;
  --colours-up-scheme-shades-neutral-shades-700: #79817e;
  --colours-up-scheme-shades-neutral-shades-800: #5c6361;
  --colours-up-scheme-shades-neutral-shades-900: #3f4543;
  --colours-up-scheme-interaction-states-hover-background-hover: #c2cac8;
  --colours-up-scheme-interaction-states-hover-background-subtle-hover: #d1d7d6;
  --colours-up-scheme-interaction-states-hover-text-hover: #3f4543;
  --colours-up-scheme-interaction-states-hover-text-dominant-hover: #3f4543;
  --colours-up-scheme-interaction-states-hover-text-recessive-hover: #3f4543;
  --colours-up-scheme-interaction-states-hover-text-link-hover: #3f4543;
  --colours-up-scheme-interaction-states-hover-accent-hover: #3f4543;
  --colours-up-scheme-interaction-states-hover-text-on-accent-hover: #ffffff;
  --colours-up-scheme-interaction-states-hover-accent-secondary-hover: #e1e5e3;
  --colours-up-scheme-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --colours-up-scheme-interaction-states-hover-stroke-hover: #5c6361;
  --colours-up-scheme-interaction-states-hover-stroke-subtle-hover: #79817e;
  --colours-up-scheme-interaction-states-pressed-background-pressed: #d1d7d6;
  --colours-up-scheme-interaction-states-pressed-background-subtle-pressed: #e1e5e3;
  --colours-up-scheme-interaction-states-pressed-text-pressed: #5c6361;
  --colours-up-scheme-interaction-states-pressed-text-dominant-pressed: #5c6361;
  --colours-up-scheme-interaction-states-pressed-text-recessive-pressed: #151a20;
  --colours-up-scheme-interaction-states-pressed-text-link-pressed: #5c6361;
  --colours-up-scheme-interaction-states-pressed-accent-pressed: #5c6361;
  --colours-up-scheme-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --colours-up-scheme-interaction-states-pressed-accent-secondary-pressed: #f0f2f1;
  --colours-up-scheme-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --colours-up-scheme-interaction-states-pressed-stroke-pressed: #3f4543;
  --colours-up-scheme-interaction-states-pressed-stroke-subtle-pressed: #5c6361;
  --colours-up-scheme-interaction-states-focus-focus: #151a20;
  --colours-up-scheme-interaction-states-focus-stroke-active: #79817e;
  --colours-up-scheme-interaction-states-disabled-background-disabled: #969f9c;
  --colours-up-scheme-interaction-states-disabled-background-subtle-disabled: #969f9c;
  --colours-up-scheme-interaction-states-disabled-text-disabled: #79817e;
  --colours-up-scheme-interaction-states-disabled-text-dominant-disabled: #79817e;
  --colours-up-scheme-interaction-states-disabled-text-recessive-disabled: #79817e;
  --colours-up-scheme-interaction-states-disabled-text-link-disabled: #79817e;
  --colours-up-scheme-interaction-states-disabled-accent-disabled: #c2cac8;
  --colours-up-scheme-interaction-states-disabled-text-on-accent-disabled: #79817e;
  --colours-up-scheme-interaction-states-disabled-accent-secondary-disabled: #c2cac8;
  --colours-up-scheme-interaction-states-disabled-text-on-accent-secondary-disabled: #79817e;
  --colours-up-scheme-interaction-states-disabled-stroke-disabled: #cae5dd;
  --colours-up-scheme-interaction-states-disabled-stroke-subtle-disabled: #c2cac8;
  --colours-up-scheme-interaction-states-visited-text-visited: #151a20;
  --colours-up-scheme-interaction-states-visited-text-link-visited: #79817e;
  --colours-up-scheme-functional-notification-notification: #0c9ce2;
  --colours-up-scheme-functional-notification-text-on-notification: #ffffff;
  --colours-up-scheme-functional-informational-standard: #a59cff;
  --colours-up-scheme-functional-informational-subtle: #d2cdff;
  --colours-up-scheme-functional-informational-hover: #a59cff;
  --colours-up-scheme-functional-informational-pressed: #3f349c;
  --colours-up-scheme-functional-success-standard: #00b381;
  --colours-up-scheme-functional-success-subtle: #c6f7e9;
  --colours-up-scheme-functional-success-hover: #2cc298;
  --colours-up-scheme-functional-success-pressed: #004834;
  --colours-up-scheme-functional-warning-standard: #f3c40a;
  --colours-up-scheme-functional-warning-subtle: #fef9e7;
  --colours-up-scheme-functional-warning-hover: #f8dc6c;
  --colours-up-scheme-functional-warning-pressed: #614e04;
  --colours-up-scheme-functional-destructive-standard: #cc0940;
  --colours-up-scheme-functional-destructive-subtle: #f5cdd8;
  --colours-up-scheme-functional-destructive-hover: #e06b8c;
  --colours-up-scheme-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
[data-scheme='Cosmote'] {
  --colours-basic-background: #ffffff;
  --colours-basic-background-subtle: #00b40b;
  --colours-basic-text: #ffffff;
  --colours-basic-text-dominant: #00a7e6;
  --colours-basic-text-recessive: #00b40b;
  --colours-basic-text-link: #00a7e6;
  --colours-basic-accent: #00a7e6;
  --colours-basic-text-on-accent: #ffffff;
  --colours-basic-accent-secondary: #000000;
  --colours-basic-text-on-secondary-accent: #ffffff;
  --colours-basic-stroke: #79817e;
  --colours-basic-stroke-subtle: #969f9c;
  --colours-basic-modal-overlay: rgba(21, 26, 32, 0.5);
  --colours-shades-accent-shades-accent-subtle: #954911;
  --colours-shades-accent-shades-accent-extra-dominant: #e1e5e3;
  --colours-shades-accent-shades-accent-dominant: #d1d7d6;
  --colours-shades-accent-shades-accent-recessive: #969f9c;
  --colours-shades-accent-shades-accent-extra-recessive: #79817e;
  --colours-shades-secondary-accent-shades-secondary-accent-subtle: #f0f2f1;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-dominant: #3f4543;
  --colours-shades-secondary-accent-shades-secondary-accent-dominant: #5c6361;
  --colours-shades-secondary-accent-shades-secondary-accent-recessive: #79817e;
  --colours-shades-secondary-accent-shades-secondary-accent-extra-recessive: #969f9c;
  --colours-shades-neutral-shades-100: #f0f2f1;
  --colours-shades-neutral-shades-200: #e1e5e3;
  --colours-shades-neutral-shades-300: #d1d7d6;
  --colours-shades-neutral-shades-400: #c2cac8;
  --colours-shades-neutral-shades-500: #b3bdba;
  --colours-shades-neutral-shades-600: #969f9c;
  --colours-shades-neutral-shades-700: #79817e;
  --colours-shades-neutral-shades-800: #5c6361;
  --colours-shades-neutral-shades-900: #3f4543;
  --colours-interaction-states-hover-background-hover: #c2cac8;
  --colours-interaction-states-hover-background-subtle-hover: #d1d7d6;
  --colours-interaction-states-hover-text-hover: #3f4543;
  --colours-interaction-states-hover-text-dominant-hover: #3f4543;
  --colours-interaction-states-hover-text-recessive-hover: #3f4543;
  --colours-interaction-states-hover-text-link-hover: #3f4543;
  --colours-interaction-states-hover-accent-hover: #3f4543;
  --colours-interaction-states-hover-text-on-accent-hover: #ffffff;
  --colours-interaction-states-hover-accent-secondary-hover: #e1e5e3;
  --colours-interaction-states-hover-text-on-accent-secondary-hover: #151a20;
  --colours-interaction-states-hover-stroke-hover: #5c6361;
  --colours-interaction-states-hover-stroke-subtle-hover: #79817e;
  --colours-interaction-states-pressed-background-pressed: #d1d7d6;
  --colours-interaction-states-pressed-background-subtle-pressed: #e1e5e3;
  --colours-interaction-states-pressed-text-pressed: #5c6361;
  --colours-interaction-states-pressed-text-dominant-pressed: #5c6361;
  --colours-interaction-states-pressed-text-recessive-pressed: #151a20;
  --colours-interaction-states-pressed-text-link-pressed: #5c6361;
  --colours-interaction-states-pressed-accent-pressed: #5c6361;
  --colours-interaction-states-pressed-text-on-accent-pressed: #ffffff;
  --colours-interaction-states-pressed-accent-secondary-pressed: #f0f2f1;
  --colours-interaction-states-pressed-text-on-accent-secondary-pressed: #151a20;
  --colours-interaction-states-pressed-stroke-pressed: #3f4543;
  --colours-interaction-states-pressed-stroke-subtle-pressed: #5c6361;
  --colours-interaction-states-focus-focus: #151a20;
  --colours-interaction-states-focus-stroke-active: #79817e;
  --colours-interaction-states-disabled-background-disabled: #969f9c;
  --colours-interaction-states-disabled-background-subtle-disabled: #969f9c;
  --colours-interaction-states-disabled-text-disabled: #79817e;
  --colours-interaction-states-disabled-text-dominant-disabled: #79817e;
  --colours-interaction-states-disabled-text-recessive-disabled: #79817e;
  --colours-interaction-states-disabled-text-link-disabled: #79817e;
  --colours-interaction-states-disabled-accent-disabled: #c2cac8;
  --colours-interaction-states-disabled-text-on-accent-disabled: #79817e;
  --colours-interaction-states-disabled-accent-secondary-disabled: #c2cac8;
  --colours-interaction-states-disabled-text-on-accent-secondary-disabled: #79817e;
  --colours-interaction-states-disabled-stroke-disabled: #cae5dd;
  --colours-interaction-states-disabled-stroke-subtle-disabled: #c2cac8;
  --colours-interaction-states-visited-text-visited: #151a20;
  --colours-interaction-states-visited-text-link-visited: #79817e;
  --colours-functional-notification-notification: #0c9ce2;
  --colours-functional-notification-text-on-notification: #ffffff;
  --colours-functional-informational-standard: #a59cff;
  --colours-functional-informational-subtle: #d2cdff;
  --colours-functional-informational-hover: #a59cff;
  --colours-functional-informational-pressed: #3f349c;
  --colours-functional-success-standard: #00b381;
  --colours-functional-success-subtle: #c6f7e9;
  --colours-functional-success-hover: #2cc298;
  --colours-functional-success-pressed: #004834;
  --colours-functional-warning-standard: #f3c40a;
  --colours-functional-warning-subtle: #fef9e7;
  --colours-functional-warning-hover: #f8dc6c;
  --colours-functional-warning-pressed: #614e04;
  --colours-functional-destructive-standard: #cc0940;
  --colours-functional-destructive-subtle: #f5cdd8;
  --colours-functional-destructive-hover: #e06b8c;
  --colours-functional-destructive-pressed: #52041a;
  --elevation-level-1: 0 1px 0 0 rgba(42, 41, 46, 0.25);
  --elevation-level-2: 0 -1px 4px 0 rgba(42, 41, 46, 0.1), 0 1px 4px 0 rgba(42, 41, 46, 0.1);
  --elevation-level-3: 0 -1px 4px 0 rgba(42, 41, 46, 0.2);
  --elevation-level-4: 0 2px 2px 0 rgba(42, 41, 46, 0.25);
  --elevation-level-5: 0 -1px 4px 0 rgba(128, 127, 133, 0.25), 0 1px 4px 0 rgba(128, 127, 133, 0.25);
  --elevation-level-6: 0 11px 22px 0 #0000001a, 0 22px 88px 0 #0000001f;
}
:root {
  --display-l: 700 3.5rem/4rem 'PF DIN Text Pro';
  --display-l-font-weight: 700;
  --display-l-font-size: 3.5rem;
  --display-l-font-family: 'PF DIN Text Pro';
  --display-l-line-height: 4rem;
  --display-l-letter-spacing: 0;
  --display-l-text-decoration: none;
  --display-m: 700 2.5rem/3.125rem 'PF DIN Text Pro';
  --display-m-font-weight: 700;
  --display-m-font-size: 2.5rem;
  --display-m-font-family: 'PF DIN Text Pro';
  --display-m-line-height: 3.125rem;
  --display-m-letter-spacing: 0;
  --display-m-text-decoration: none;
  --display-s: 700 2rem/2rem 'PF DIN Text Pro';
  --display-s-font-weight: 700;
  --display-s-font-size: 2rem;
  --display-s-font-family: 'PF DIN Text Pro';
  --display-s-line-height: 2rem;
  --display-s-letter-spacing: 0;
  --display-s-text-decoration: none;
  --title-l: 700 1.5rem/1.75rem 'PF DIN Text Pro';
  --title-l-font-weight: 700;
  --title-l-font-size: 1.5rem;
  --title-l-font-family: 'PF DIN Text Pro';
  --title-l-line-height: 1.75rem;
  --title-l-letter-spacing: 0;
  --title-l-text-decoration: none;
  --title-m: 700 1.25rem/1.5rem 'PF DIN Text Pro';
  --title-m-font-weight: 700;
  --title-m-font-size: 1.25rem;
  --title-m-font-family: 'PF DIN Text Pro';
  --title-m-line-height: 1.5rem;
  --title-m-letter-spacing: 0;
  --title-m-text-decoration: none;
  --title-s: 700 1.125rem/1.5rem 'PF DIN Text Pro';
  --title-s-font-weight: 700;
  --title-s-font-size: 1.125rem;
  --title-s-font-family: 'PF DIN Text Pro';
  --title-s-line-height: 1.5rem;
  --title-s-letter-spacing: 0;
  --title-s-text-decoration: none;
  --subtitle-l: 500 1.125rem/1.5rem 'PF DIN Text Pro';
  --subtitle-l-font-weight: 500;
  --subtitle-l-font-size: 1.125rem;
  --subtitle-l-font-family: 'PF DIN Text Pro';
  --subtitle-l-line-height: 1.5rem;
  --subtitle-l-letter-spacing: 0;
  --subtitle-l-text-decoration: none;
  --subtitle-m: 400 1.125rem/1.5rem 'PF DIN Text Pro';
  --subtitle-m-font-weight: 400;
  --subtitle-m-font-size: 1.125rem;
  --subtitle-m-font-family: 'PF DIN Text Pro';
  --subtitle-m-line-height: 1.5rem;
  --subtitle-m-letter-spacing: 0;
  --subtitle-m-text-decoration: none;
  --subtitle-s: 700 1rem/1.125rem 'PF DIN Text Pro';
  --subtitle-s-font-weight: 700;
  --subtitle-s-font-size: 1rem;
  --subtitle-s-font-family: 'PF DIN Text Pro';
  --subtitle-s-line-height: 1.125rem;
  --subtitle-s-letter-spacing: 0;
  --subtitle-s-text-decoration: none;
  --body-m-bold: 700 1rem/1.125rem 'PF DIN Text Pro';
  --body-m-bold-font-weight: 700;
  --body-m-bold-font-size: 1rem;
  --body-m-bold-font-family: 'PF DIN Text Pro';
  --body-m-bold-line-height: 1.125rem;
  --body-m-bold-letter-spacing: 0;
  --body-m-bold-text-decoration: none;
  --body-m-regular: 400 1rem/1.125rem 'PF DIN Text Pro';
  --body-m-regular-font-weight: 400;
  --body-m-regular-font-size: 1rem;
  --body-m-regular-font-family: 'PF DIN Text Pro';
  --body-m-regular-line-height: 1.125rem;
  --body-m-regular-letter-spacing: 0;
  --body-m-regular-text-decoration: none;
  --body-s-bold: 700 0.875rem/1rem 'PF DIN Text Pro';
  --body-s-bold-font-weight: 700;
  --body-s-bold-font-size: 0.875rem;
  --body-s-bold-font-family: 'PF DIN Text Pro';
  --body-s-bold-line-height: 1rem;
  --body-s-bold-letter-spacing: 0;
  --body-s-bold-text-decoration: none;
  --body-s-regular: 400 0.875rem/1rem 'PF DIN Text Pro';
  --body-s-regular-font-weight: 400;
  --body-s-regular-font-size: 0.875rem;
  --body-s-regular-font-family: 'PF DIN Text Pro';
  --body-s-regular-line-height: 1rem;
  --body-s-regular-letter-spacing: 0;
  --body-s-regular-text-decoration: none;
  --button-l: 500 1.125rem/1.25rem 'PF DIN Text Pro';
  --button-l-font-weight: 500;
  --button-l-font-size: 1.125rem;
  --button-l-font-family: 'PF DIN Text Pro';
  --button-l-line-height: 1.25rem;
  --button-l-letter-spacing: 0;
  --button-l-text-decoration: none;
  --button-m: 500 1rem/1rem 'PF DIN Text Pro';
  --button-m-font-weight: 500;
  --button-m-font-size: 1rem;
  --button-m-font-family: 'PF DIN Text Pro';
  --button-m-line-height: 1rem;
  --button-m-letter-spacing: 0;
  --button-m-text-decoration: none;
  --button-s: 500 0.75rem/0.875rem 'PF DIN Text Pro';
  --button-s-font-weight: 500;
  --button-s-font-size: 0.75rem;
  --button-s-font-family: 'PF DIN Text Pro';
  --button-s-line-height: 0.875rem;
  --button-s-letter-spacing: 0;
  --button-s-text-decoration: none;
  --microcopy-bold: 700 0.75rem/0.875rem 'PF DIN Text Pro';
  --microcopy-bold-font-weight: 700;
  --microcopy-bold-font-size: 0.75rem;
  --microcopy-bold-font-family: 'PF DIN Text Pro';
  --microcopy-bold-line-height: 0.875rem;
  --microcopy-bold-letter-spacing: 0;
  --microcopy-bold-text-decoration: none;
  --microcopy-regular: 400 0.75rem/0.875rem 'PF DIN Text Pro';
  --microcopy-regular-font-weight: 400;
  --microcopy-regular-font-size: 0.75rem;
  --microcopy-regular-font-family: 'PF DIN Text Pro';
  --microcopy-regular-line-height: 0.875rem;
  --microcopy-regular-letter-spacing: 0;
  --microcopy-regular-text-decoration: none;
  --link-l: 500 1.125rem/1.25rem 'PF DIN Text Pro';
  --link-l-font-weight: 500;
  --link-l-font-size: 1.125rem;
  --link-l-font-family: 'PF DIN Text Pro';
  --link-l-line-height: 1.25rem;
  --link-l-letter-spacing: 0;
  --link-l-text-decoration: underline;
  --link-m: 500 1rem/1rem 'PF DIN Text Pro';
  --link-m-font-weight: 500;
  --link-m-font-size: 1rem;
  --link-m-font-family: 'PF DIN Text Pro';
  --link-m-line-height: 1rem;
  --link-m-letter-spacing: 0;
  --link-m-text-decoration: underline;
  --link-s: 500 0.875rem/1rem 'PF DIN Text Pro';
  --link-s-font-weight: 500;
  --link-s-font-size: 0.875rem;
  --link-s-font-family: 'PF DIN Text Pro';
  --link-s-line-height: 1rem;
  --link-s-letter-spacing: 0;
  --link-s-text-decoration: underline;
}
